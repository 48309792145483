import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import UserService from "../../../services/user.service";
import ProductService from "../../../services/product.service";
import { useAuth } from "../../../services/auth/use-auth";
import { DataGrid, GridRowsProp, GridColDef  } from '@mui/x-data-grid';
import { Button, Card, CardContent, CardHeader, Container, Grid, Typography } from "@mui/material";
import moment from 'moment';


function MembershipTab() {
  const auth = useAuth();
  const navigate = useNavigate();

  //const [data, setData] = useState([]);
  //const [page, setPage] = useState(0);
  const [subscription, setSubscription] = useState([]);
  const [credits, setCredits] = useState([]);
  const [products, setProducts] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [datarows, setRows] = useState([]);
  
  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Time Ago', width: 125 },
    { field: 'col2', headerName: 'Date', width: 100 },
    { field: 'col3', headerName: 'Credits', width: 75 },
    { field: 'col4', headerName: 'Price', width: 75 }, 
    { field: 'col5', headerName: 'Description', width: '100%'}   
  ];
  
  useEffect(() => {
    
    const pageData = async () => {
      if (!auth.user) return;
      getSubscription();
      getProducts();
      getPurchases();
    };

    const getProducts = async () => {
      if (!auth.user) return;
      // get products
      const productResponse = await ProductService.GetProductList(auth.user.uid, auth.user.auth.currentUser.accessToken);
      // console.log("Products:", productResponse);
      setProducts(productResponse);
   };

   const getPurchases = async (id) => {
    if (!auth.user) return;
    // get purchases
    const productResponse = await ProductService.GetProductPurchases(auth.user.uid, auth.user.auth.currentUser.accessToken);

    var rows = [];
    productResponse.forEach(p => {
      const timeago = moment(p.productPurchaseDate).fromNow();
      var tempDate = new Date(p.productPurchaseDate).toLocaleDateString(); 
     
      rows.push({ 
        id: p.productPurchaseId, 
        col1: timeago, 
        col2: tempDate, 
        col3: p.productPurchasePriceCredits, 
        col4: p.productPurchasePriceMoney,
        col5: p.externalTransactionMessage
      });
    });
    setRows(rows);
    // console.log("getPurchases:", productResponse);
    setPurchases(productResponse);
 };

    const getSubscription = async () => {
      if (auth.user) {
        const response = await UserService.GetUserSubscription(auth.user.uid, auth.user.auth.currentUser.accessToken);
        // console.log("Logged in, credit  info for auth.user.id:", auth.user.uid,"\n", response);        
        setCredits(response.subscription.creditProfile);
        setSubscription(response.subscription);
        // console.log("Subscription: ", JSON.stringify(response.subscription));
      }
      else {
        //const response = await UserService.GetUserCredits("User123");
        var credit = { creditProfile: { numSubscriptionCreditsRemaining: -1, numWalletCreditsRemaining: -1, numPendingCredits: -1} };
        setSubscription(credit);
        // console.log("No Subscription - Not Logged in ", JSON.stringify(credit));
      };
    };
    
    pageData();
  }, [auth.user]);

  const FreePlan = (
    <Card>
      <div className="card-body text-center d-flex flex-column">
        <div className="card-header text-bg-secondary py-3">Current Membership</div>
        <br />
        <h4 className="text-center card-title fw-bold mb-4">{subscription.subscriptionName}</h4>
        
        <h5 className="text-center fw-bold">Subscription Credits Balance: {credits.numSubscriptionCreditsRemaining}</h5>
        <h5 className="text-center fw-bold">Wallet Credits Balance: {credits.numWalletCreditsRemaining}</h5>
        <br />  
        <div className="mb-4">
              <Button
                sx={{ width: '150px', ml: 4 }} size="small" variant="contained" color="primary"
                onClick={() => navigate("/credits")}
              >
                Buy Credits
              </Button>   
          </div>
          <div className="mb-4">
              <Link
                // setup an email link
                sx={{ width: '150px', ml: 4 }} 
                size="small" 
                variant="contained" 
                color="primary"
                to="/about"
              >
                Seeking to Cancel Membership?
              </Link>   
          </div>
      </div>
    </Card>
  );

  const BertHorizontalRule = (
    <hr  style={{
      color: '#000000',
      backgroundColor: '#000000',
      height: .5,
      borderColor : '#000000'
    }}/>
  );

  return (
    <div className="container">
      <div className="row my-5 mb-2">
        <div className="col d-flex justify-content-center">
          <h3 className="fw-bold">Manage Membership</h3>
        </div>
      </div>
       {FreePlan}
       <br />
       { /*
      <Card>
        <div className="card-body text-center d-flex flex-column">
          <div className="card-header text-bg-secondary py-3">Payment Methods (BETA RELEASE)</div>
            <h6 className="fw-italic"></h6>
        </div>
       
        <div className="">
              <a aria-disabled="true" className="text-secondary d-block text-decoration-underline">
              <small>+ Add New Payment Method</small>
              </a>
        </div>
       
      </Card> 
  */}
    {BertHorizontalRule}
    <Card>
        <div className="card-body text-center d-flex flex-column">
          <div className="card-header text-bg-secondary py-3">Billing History</div>
        <div className="text-left fw-bold" ></div>
      </div>
      
    <div className="">
        <div style={{ height: '350px', width: '100%' }}>
          <DataGrid rows={datarows} columns={columns} />
        </div>
      </div>
      </Card>
    </div>
 
  );
}

export default MembershipTab;
