import React from "react";

export function getThemes() {
    // theme list
    var themes = [
        "love",
        "hate",
        "war",
        "peace",
        "death",
        "life",
        "fear",
        "courage",
        "hope",
        "despair",
        "joy",
        "sadness",
        "anger",
        "surprise",
        "disgust",
        "trust",
    ];
    return themes.sort();
};

export function getStoryTypesForCategory(category) {
  
  // Default story types for unknown categories
  const defaultTypes = [
    "Descriptive Story",
    "Historical Account",
    "Personal Experience",
    "Documentary Style",
    "Creative Fiction",
    "Journalistic Report",
    "Educational Piece",
    "Marketing Copy"
  ];

  const storyTypeMap = {
    "Animals": [
      "Wildlife Documentary", "Pet Biography", "Conservation Story", "Animal Behavior Study",
      "Zoo Adventure", "Endangered Species Report", "Nature Guide", "Veterinary Tale"
    ],
    "Anime": [
      "Character Origin Story", "Episode Synopsis", "Series Pitch", "Character Arc",
      "Fan Fiction", "Alternate Timeline", "Crossover Story", "Manga Adaptation"
    ],
    "Architecture": [
      "Building History", "Design Story", "Architectural Review", "Urban Legend",
      "Construction Journey", "Historical Significance", "Renovation Tale", "Blueprint Story"
    ],
    "Art": [
      "Artist Statement", "Exhibition Review", "Creative Process", "Artwork History",
      "Gallery Guide", "Artistic Movement", "Inspiration Story", "Technique Description"
    ],
    "Beauty": [
      "Product Story", "Beauty Routine", "Transformation Tale", "Tutorial Narrative",
      "Industry Insight", "Traditional Beauty Practice", "Makeover Journey", "Style Evolution"
    ],
    "Business": [
      "Company History", "Success Story", "Startup Journey", "Industry Analysis",
      "Leadership Biography", "Market Report", "Innovation Story", "Case Study"
    ],
    "Characters": [
      "Origin Story", "Character Biography", "Personal Journey", "Diary Entry",
      "Interview Profile", "Life Story", "Secret History", "Relationship Tale"
    ],
    "Cinema": [
      "Movie Review", "Behind the Scenes", "Director's Vision", "Actor's Journey",
      "Film Analysis", "Production Story", "Scene Breakdown", "Movie Pitch"
    ],
    "Comics": [
      "Superhero Origin", "Villain Backstory", "Comic Book Review", "Character Profile",
      "Story Arc Pitch", "Crossover Event", "Adventure Tale", "Secret Identity Story"
    ],
    "Culinary": [
      "Recipe Origin", "Chef's Story", "Restaurant Tale", "Food History",
      "Cooking Journey", "Cultural Food Story", "Secret Recipe", "Kitchen Adventure"
    ],
    "Education": [
      "Learning Journey", "Teaching Story", "Academic Discovery", "Student Experience",
      "Educational Innovation", "Classroom Tale", "Research Story", "Mentorship Account"
    ],
    "Environments": [
      "Ecosystem Story", "Climate Tale", "Nature Journal", "Environmental Report",
      "Conservation Narrative", "Habitat Story", "Weather Event", "Geographic Feature"
    ],
    "Fantasy": [
      "Magic Tale", "Epic Quest", "Mythical Creature", "Enchanted Object",
      "Hero's Journey", "Magical World", "Prophecy Story", "Legend Creation"
    ],
    "Fashion": [
      "Style Evolution", "Design Journey", "Trend Story", "Collection Narrative",
      "Fashion House History", "Runway Tale", "Designer Biography", "Garment Story"
    ],
    "Gaming": [
      "Game Review", "Character Backstory", "Level Design", "Gaming Achievement",
      "Easter Egg Tale", "Strategy Guide", "Player Journey", "Game Development Story"
    ],
    "Horror": [
      "Ghost Story", "Mystery Tale", "Supernatural Event", "Haunted Location",
      "Monster Origin", "Psychological Thriller", "Urban Legend", "Dark History"
    ],
    "Interior Design": [
      "Room Transformation", "Design Journey", "Space Story", "Renovation Tale",
      "Style Evolution", "Home History", "Designer Vision", "Makeover Narrative"
    ],
    "Nature": [
      "Environmental Tale", "Weather Story", "Seasonal Change", "Wildlife Account",
      "Forest Journey", "Ocean Adventure", "Mountain Story", "Desert Narrative"
    ],
    "Portraits": [
      "Personal Biography", "Life Story", "Character Study", "Historical Figure",
      "Family Legacy", "Professional Profile", "Love Story", "Identity Tale"
    ],
    "Robots": [
      "AI Story", "Robot Creation", "Machine Learning Tale", "Automation Journey",
      "Robot-Human Interaction", "Future Vision", "Technical Specification", "Innovation Story"
    ],
    "Science": [
      "Discovery Story", "Research Journey", "Experiment Log", "Scientific Biography",
      "Innovation Tale", "Lab Report", "Technology Development", "Breakthrough Narrative"
    ],
    "Science Fiction": [
      "Future Vision", "Space Adventure", "Time Travel Tale", "Alien Contact",
      "Tech Revolution", "Dystopian Story", "AI Evolution", "Parallel Universe"
    ],
    "Sports": [
      "Athlete Biography", "Team History", "Championship Story", "Training Journey",
      "Competition Tale", "Sports Evolution", "Coach's Story", "Game Analysis"
    ],
    "Technology": [
      "Tech Innovation", "Product Development", "Digital Journey", "Startup Story",
      "App Creation", "System Design", "Future Vision", "User Experience"
    ],
    "Transportation": [
      "Vehicle History", "Journey Tale", "Innovation Story", "Transit Evolution",
      "Adventure Log", "Engineering Tale", "Design Story", "Travel Account"
    ],
    "Travel": [
      "Adventure Journal", "Destination Guide", "Cultural Experience", "Travel Memory",
      "Explorer's Log", "Local History", "Hidden Gem", "Journey Narrative"
    ],
    "Urban": [
      "City Tale", "Street Story", "Neighborhood History", "Urban Development",
      "Metropolitan Life", "Community Profile", "City Evolution", "Local Legend"
    ],
    "Work": [
      "Career Journey", "Professional Profile", "Workplace Story", "Industry Tale",
      "Success Story", "Company History", "Job Experience", "Business Case"
    ]
  };

  return (storyTypeMap[category] || defaultTypes).sort();
};

export function getMoods() {
    var moods = [
        "happy",
        "sad",
        "angry",
        "fearful",
        "disgusted",
        "surprised",
        "neutral",
    ];
    return moods.sort();
};

export function getAtmospheric() {
    var atmospheric = [
        "sunny",
        "cloudy",
        "rainy",
        "snowy",
        "windy",
        "stormy",
        "foggy",
        "clear",
        "hot",
        "cold",
        "humid",
        "dry",
        "dark",
        "bright",
        "noisy",
        "quiet",
        "crowded",
        "deserted",
        "calm",
        "exciting",
        "boring",
        "relaxing",
        "stressful",
        "dangerous",
        "safe",
        "clean",
        "dirty",
        "beautiful",
        "ugly",
        "familiar",
        "unfamiliar",
        "comfortable",
        "uncomfortable",
        "friendly",
        "unfriendly",
        "romantic",
        "scary",
        "peaceful",
        "chaotic",
        "sad",
        "happy",
        "angry",
        "fearful",
        "disgusted",
        "surprised",
        "neutral",
    ];
    return atmospheric.sort();
};

export function getGenres() {
    var genres = [
        "action",
        "adventure",
        "comedy",
        "crime",
        "drama",
        "fantasy",
        "historical",
        "historical fiction",
        "horror",
        "magical realism",
        "mystery",
        "paranoid fiction",
        "philosophical",
        "political",
        "romance",
        "saga",
        "satire",
        "science fiction",
        "social",
        "speculative",
        "thriller",
        "urban",
        "western",
        "animation",
        "anime",
        "comic",
        "manga",
        "children's",
        "young adult",
        "adult",
        "fiction",
        "non-fiction",
        "biography",
        "autobiography",
        "memoir",
        "essay",
        "journalism",
        "personal narrative",
        "reference book",
        "self-help book",
        "textbook",
        "poetry",
        "prose",
        "screenplay",
        "short story",
        "novel",
        "novella",
        "play",
        "tragedy",
        "tragic comedy",
        "musical",
        "opera",
    ];
    return genres.sort();
};

export function getPostTypes() {
    var postTypes = [
        "motivation",
        "career",
        "resilience",
        "share picture",
        "happy birthday",
        "congratulations",
        "thank you",
        "inspiration",
        "funny",
        "love",
        "friendship",
        "family",
        "pets",
        "holiday",
        "travel",
        "food",
        "fitness",
        "health",
        "music",
        "movies",
        "books",
        "sports",
        "games",
        "news",
        "politics",
    ];
    return postTypes.sort();
};

/* AGENT CONTEXT 
MarketingSocialMediaAgent
MarketingWebsiteCopyAgent
MarketingNewsletterAgent
MarketingEmailCampaignAgent

NarratorAgentFirstPersonSarcastic
NarratorAgentFirstPersonMysterious
NarratorAgentFirstPersonSuspenseful
NarratorAgentFirstPersonMysterious
NarratorAgentFirstPersonNostalgic
NarratorAgentFirstPersonWhimsical
NarratorAgentFirstPersonInformal

NarratorAgentSecondPersonHumorous
NarratorAgentSecondPersonDramatic
NarratorAgentSecondPersonSuspenseful
NarratorAgentSecondPersonInspirational
NarratorAgentSecondPersonFormal
NarratorAgentSecondPersonInformal
NarratorAgentSecondPersonSarcastic

NarratorAgentThirdPersonOmniscientHumorous
NarratorAgentThirdPersonOmniscientMysterious
NarratorAgentThirdPersonOmniscientDramatic
NarratorAgentThirdPersonOmniscientEpic
NarratorAgentThirdPersonOmniscientFormal
NarratorAgentThirdPersonOmniscientInspirational
NarratorAgentThirdPersonOmniscientNostalgic
NarratorAgentThirdPersonOmniscientWhimsical
NarratorAgentThirdPersonSurreal
NarratorAgentThirdPersonSteampunk

NarratorAgentThirdPersonLimitedHumorous
NarratorAgentThirdPersonLimitedMysterious
NarratorAgentThirdPersonLimitedMelancholic
NarratorAgentThirdPersonLimitedRomantic
NarratorAgentThirdPersonLimitedSuspenseful

FantasyCampaignCreatorAgent
FantasyCharacterCreatorAgent
FantasyCampaignFullWithActsAgent
FantasyEncounterGeneratorAgent
FantasyQuestDesignerAgent
FantasyPlotDeveloperAgent
FantasyEncounterGeneratorAgent

ScienceFictionRewriterAgent
SciFiWorldBuilderAgent
SciFiBattleSceneAgent
SciFiAlienSpeciesAgent
SciFiCharacterAgent
SciFiDialogueAgent
SciFiPlotGeneratorAgent

NatureDocumentaryAgent
NatureAnimalStoryAgent
NatureEvolutionaryHistoryAgent
NatureResearchSummaryAgent
NatureEnvironmentProfileAgent
NatureResearchSummaryAgent

PlaywrightAgent
MovieScriptWriterAgent
CinematicSceneWriterAgent
MovieTreatmentWriterAgent
PoemWriterAgent
NovelWriterAgent

CulinaryRecipeDeveloperAgent
CulinaryMolecularGastronomistAgent
CulinaryPastryChefAgent
CulinaryFoodCriticAgent

CharacterFantasyCharacterAgent
CharacterAnimeCharacterAgent
CharacterRealisticHumanCharacterAgent
CharacterComicBookCharacterAgent
CharacterAlienCharacterAgent
CharacterFantasyCharacterAgent

ComicScriptAgent
ComicPlotAgent
ComicPageAgent
ComicDialogueAgent
ComicsFullComicBookAgent

FloralArrangementAgent
FloralSymbolismAgent
HospitalityExperienceAgent
HospitalityVenueAgent

EnvironmentalScientistAgent
EnvironmentAlienLandscapeAgent
EnvironmentalLandscapeAgent
EnvironmentExoplanetDescriptionAgent
EnvironmentEcologicalHabitatAgent
EnvironmentGeologicalFormationAgent
EnvironmentPlanetaryExplorationAgent

FashionApparelDesignAgent
FashionAccessoryDesignAgent
FashionTrendAnalysisAgent
FashionMarketingAgent
FashionTextilePatternAgent
FashionIllustrationAgent
FashionSustainableFashionAgent

InteriorDesignResidentialDesignAgent
InteriorDesignCommercialDesignAgent
InteriorDesignHospitalityDesignAgent
InteriorDesignSustainableDesignAgent
InteriorDesignStylingAgent
InteriorDesignFurnitureDesignAgent
InteriorDesignLightingDesignAgent

MaterialSwatchAgent
TextureSwatchAgent
*/
export function getAgentDescriptions(agentName) {
  console.log("getAgentDescriptions() - Agent Name: ", agentName);

  let description = "Quick Download";

  // preconditions - null and empty string check
  if (agentName === null || agentName === "") {
      return description;
  }

  switch (agentName) {
    case "MarketingPressReleaseAgent": description = "Marketing Press Release Agent Description"; break;
    case "MarketingSocialMediaAgent": description = "Marketing Social Media Agent Description"; break;
    case "MarketingWebsiteCopyAgent": description = "Marketing Website Copy Agent Description"; break;
    case "MarketingNewsletterAgent": description = "Marketing Newsletter Agent Description"; break;
    case "MarketingEmailCampaignAgent": description = "Marketing Email Campaign Agent Description"; break;

    case "NarratorAgentFirstPersonSarcastic": description = "Narrator Agent First Person Sarcastic Description"; break;
    case "NarratorAgentFirstPersonMysterious": description = "Narrator Agent First Person Mysterious Description"; break;
    case "NarratorAgentFirstPersonSuspenseful": description = "Narrator Agent First Person Suspenseful Description"; break;
    case "NarratorAgentFirstPersonNostalgic": description = "Narrator Agent First Person Nostalgic Description"; break;
    case "NarratorAgentFirstPersonWhimsical": description = "Narrator Agent First Person Whimsical Description"; break;
    case "NarratorAgentFirstPersonInformal": description = "Narrator Agent First Person Informal Description"; break;

    case "NarratorAgentSecondPersonHumorous": description = "Narrator Agent Second Person Humorous Description"; break;
    case "NarratorAgentSecondPersonDramatic": description = "Narrator Agent Second Person Dramatic Description"; break;
    case "NarratorAgentSecondPersonSuspenseful": description = "Narrator Agent Second Person Suspenseful Description"; break;
    case "NarratorAgentSecondPersonInspirational": description = "Narrator Agent Second Person Inspirational Description"; break;
    case "NarratorAgentSecondPersonFormal": description = "Narrator Agent Second Person Formal Description"; break;
    case "NarratorAgentSecondPersonInformal": description = "Narrator Agent Second Person Informal Description"; break;
    case "NarratorAgentSecondPersonSarcastic": description = "Narrator Agent Second Person Sarcastic Description"; break;

    case "NarratorAgentThirdPersonOmniscientHumorous": description = "Narrator Agent Third Person Omniscient Humorous Description"; break;
    case "NarratorAgentThirdPersonOmniscientMysterious": description = "Narrator Agent Third Person Omniscient Mysterious Description"; break;
    case "NarratorAgentThirdPersonOmniscientDramatic": description = "Narrator Agent Third Person Omniscient Dramatic Description"; break;
    case "NarratorAgentThirdPersonOmniscientEpic": description = "Narrator Agent Third Person Omniscient Epic Description"; break;
    case "NarratorAgentThirdPersonOmniscientFormal": description = "Narrator Agent Third Person Omniscient Formal Description"; break;
    case "NarratorAgentThirdPersonOmniscientInspirational": description = "Narrator Agent Third Person Omniscient Inspirational Description"; break;
    case "NarratorAgentThirdPersonOmniscientNostalgic": description = "Narrator Agent Third Person Omniscient Nostalgic Description"; break;
    case "NarratorAgentThirdPersonOmniscientWhimsical": description = "Narrator Agent Third Person Omniscient Whimsical Description"; break;
    case "NarratorAgentThirdPersonSurreal": description = "Narrator Agent Third Person Surreal Description"; break;
    case "NarratorAgentThirdPersonSteampunk": description = "Narrator Agent Third Person Steampunk Description"; break;

    case "NarratorAgentThirdPersonLimitedHumorous": description = "Narrator Agent Third Person Limited Humorous Description"; break;
    case "NarratorAgentThirdPersonLimitedMysterious": description = "Narrator Agent Third Person Limited Mysterious Description"; break;
    case "NarratorAgentThirdPersonLimitedMelancholic": description = "Narrator Agent Third Person Limited Melancholic Description"; break;
    case "NarratorAgentThirdPersonLimitedRomantic": description = "Narrator Agent Third Person Limited Romantic Description"; break;
    case "NarratorAgentThirdPersonLimitedSuspenseful": description = "Narrator Agent Third Person Limited Suspenseful Description"; break;

    case "FantasyCampaignCreatorAgent": description = "Fantasy Campaign Creator Agent Description"; break;
    case "FantasyCharacterCreatorAgent": description = "Fantasy Character Creator Agent Description"; break;
    case "FantasyCampaignFullWithActsAgent": description = "Fantasy Campaign Full With Acts Agent Description"; break;
    case "FantasyEncounterGeneratorAgent": description = "Fantasy Encounter Generator Agent Description"; break;
    case "FantasyQuestDesignerAgent": description = "Fantasy Quest Designer Agent Description"; break;
    case "FantasyPlotDeveloperAgent": description = "Fantasy Plot Developer Agent Description"; break;
    
    case "ScienceFictionRewriterAgent": description = "Science Fiction Rewriter Agent Description"; break;
    case "SciFiWorldBuilderAgent": description = "SciFi World Builder Agent Description"; break;
    case "SciFiBattleSceneAgent": description = "SciFi Battle Scene Agent Description"; break;
    case "SciFiAlienSpeciesAgent": description = "SciFi Alien Species Agent Description"; break;
    case "SciFiCharacterAgent": description = "SciFi Character Agent Description"; break;
    case "SciFiDialogueAgent": description = "SciFi Dialogue Agent Description"; break;
    case "SciFiPlotGeneratorAgent": description = "SciFi Plot Generator Agent Description"; break;

    case "NatureDocumentaryAgent": description = "Nature Documentary Agent Description"; break;
    case "NatureAnimalStoryAgent": description = "Nature Animal Story Agent Description"; break;
    case "NatureEvolutionaryHistoryAgent": description = "Nature Evolutionary History Agent Description"; break;
    case "NatureResearchSummaryAgent": description = "Nature Research Summary Agent Description"; break;
    case "NatureEnvironmentProfileAgent": description = "Nature Environment Profile Agent Description"; break;
    
    case "PlaywrightAgent": description = "Playwright Agent Description"; break;
    case "MovieScriptWriterAgent": description = "Movie Script Writer Agent Description"; break;
    case "CinematicSceneWriterAgent": description = "Cinematic Scene Writer Agent Description"; break;
    case "MovieTreatmentWriterAgent": description = "Movie Treatment Writer Agent Description"; break;
    case "PoemWriterAgent": description = "Poem Writer Agent Description"; break;
    case "NovelWriterAgent": description = "Novel Writer Agent Description"; break;

    case "CulinaryRecipeDeveloperAgent": description = "Culinary Recipe Developer Agent Description"; break;
    case "CulinaryMolecularGastronomistAgent": description = "Culinary Molecular Gastronomist Agent Description"; break;
    case "CulinaryPastryChefAgent": description = "Culinary Pastry Chef Agent Description"; break;
    case "CulinaryFoodCriticAgent": description = "Culinary Food Critic Agent Description"; break;

    case "CharacterFantasyCharacterAgent": description = "Character Fantasy Character Agent Description"; break;
    case "CharacterAnimeCharacterAgent": description = "Character Anime Character Agent Description"; break;
    case "CharacterRealisticHumanCharacterAgent": description = "Character Realistic Human Character Agent Description"; break;
    case "CharacterComicBookCharacterAgent": description = "Character Comic Book Character Agent Description"; break;
    case "CharacterAlienCharacterAgent": description = "Character Alien Character Agent Description"; break;
    
    case "ComicScriptAgent": description = "Comic Script Agent Description"; break;
    case "ComicPlotAgent": description = "Comic Plot Agent Description"; break;
    case "ComicPageAgent": description = "Comic Page Agent Description"; break;
    case "ComicDialogueAgent": description = "Comic Dialogue Agent Description"; break;
    case "ComicsFullComicBookAgent": description = "Comics Full Comic Book Agent Description"; break;

    case "FloralArrangementAgent": description = "Floral Arrangement Agent Description"; break;
    case "FloralSymbolismAgent": description = "Floral Symbolism Agent Description"; break;
    case "HospitalityExperienceAgent": description = "Hospitality Experience Agent Description"; break;
    case "HospitalityVenueAgent": description = "Hospitality Venue Agent Description"; break;

    case "EnvironmentalScientistAgent": description = "Environmental Scientist Agent Description"; break;
    case "EnvironmentAlienLandscapeAgent": description = "Environment Alien Landscape Agent Description"; break;
    case "EnvironmentalLandscapeAgent": description = "Environmental Landscape Agent Description"; break;
    case "EnvironmentExoplanetDescriptionAgent": description = "Environment Exoplanet Description Agent Description"; break;
    case "EnvironmentEcologicalHabitatAgent": description = "Environment Ecological Habitat Agent Description"; break;
    case "EnvironmentGeologicalFormationAgent": description = "Environment Geological Formation Agent Description"; break;
    case "EnvironmentPlanetaryExplorationAgent": description = "Environment Planetary Exploration Agent Description"; break;

    case "FashionApparelDesignAgent": description = "Fashion Apparel Design Agent Description"; break;
    case "FashionAccessoryDesignAgent": description = "Fashion Accessory Design Agent Description"; break;
    case "FashionTrendAnalysisAgent": description = "Fashion Trend Analysis Agent Description"; break;
    case "FashionMarketingAgent": description = "Fashion Marketing Agent Description"; break;
    case "FashionTextilePatternAgent": description = "Fashion Textile Pattern Agent Description"; break;
    case "FashionIllustrationAgent": description = "Fashion Illustration Agent Description"; break;
    case "FashionSustainableFashionAgent": description = "Fashion Sustainable Fashion Agent Description"; break;

    case "InteriorDesignResidentialDesignAgent": description = "Interior Design Residential Design Agent Description"; break;
    case "InteriorDesignCommercialDesignAgent": description = "Interior Design Commercial Design Agent Description"; break;
    case "InteriorDesignHospitalityDesignAgent": description = "Interior Design Hospitality Design Agent Description"; break;
    case "InteriorDesignSustainableDesignAgent": description = "Interior Design Sustainable Design Agent Description"; break;
    case "InteriorDesignStylingAgent": description = "Interior Design Styling Agent Description"; break;
    case "InteriorDesignFurnitureDesignAgent": description = "Interior Design Furniture Design Agent Description"; break;
    case "InteriorDesignLightingDesignAgent": description = "Interior Design Lighting Design Agent Description"; break;

    case "MaterialSwatchAgent": description = "Material Swatch Agent Description"; break;
    case "TextureSwatchAgent": description = "Texture Swatch Agent Description"; break;

    default: description = "AI Agent Description"; break;
  }
  return description;
};

export function determineAgenticTasks(category) {
    //console.log("determineAgenticTasks() - image category: ", category);

    let agenticTasks = "Quick Download";

    // preconditions - null and empty string check
    if (category === null || category === "") {
        return agenticTasks;
    }
    
    //agenticTasks = ["Quick Download", "Create Story", "Vary Image"];
    
    
    if (category === "People") {
      agenticTasks = ["Quick Download", "Create Script", "Create Backstory"];
    } else if (category === "Animals") {
      agenticTasks = ["Quick Download", "Create Story", "Create Backstory", "Vary Image"];
    } else if (category === "Nature") { 
      agenticTasks = ["Quick Download", "Create Story", "Create Backstory", "Vary Image"];
    } else if (category === "Fantasy") {
      agenticTasks = ["Quick Download", "Create Encounter", "Create Trailer", "Create Fantasy Campaign"];
    } else if (category === "Science Fiction") {
      agenticTasks = ["Quick Download", "Create Battle Story", "Create Trailer", "Create Character Arc", "Create Backstory"];
    } else if (category === "Anime") {
      agenticTasks = ["Quick Download", "Create Story", "Create Scene", "Create Script", "Create Character"];
    } else if (category === "Beauty") {
      agenticTasks = ["Quick Download", "Create Story", "Create Love Letter", "Create Look", "Create Hair Style", "Create Look"];
    } else if (category === "Characters") { 
      agenticTasks = ["Quick Download", "Create Character Design", "Create Character Arc", "Create Backstory", "Create Comics Page"];
    } else if (category === "Cinema") {
      agenticTasks = ["Quick Download", "Create Poster", "Create Trailer", "Create Scene", "Create Script"];
    } else if (category === "Culinary") {
      agenticTasks = ["Quick Download", "Create Recipe", "Create Story", "Create Blog", "Create Demo"];
    } else if (category === "Fashion") {
      agenticTasks = ["Quick Download", "Create Look", "Create Story", "Create Blog", "Create Show"];
    } else if (category === "Floral Arrangements") {
      agenticTasks = ["Quick Download", "Create Arrangement", "Create Story", "Create Blog", "Create Show"];
    } else if (category === "Holidays") {
      agenticTasks = ["Quick Download", "Create Story", "Create Blog", "Create Card", "Create Postcard"];
    } else if (category === "Horror") {
      agenticTasks = ["Quick Download", "Create Story", "Create Scene", "Create Script", "Create Campaign"];
    } else if (category === "Interior Design") {
      agenticTasks = ["Quick Download", "Build Design", "Create Blog", "Create Walkthru"];
    } else if (category === "Logos") {
      agenticTasks = ["Quick Download", "Create Press Release", "Create Design", "Create About Us", "Create Ad Campaign"];
    } else if (category === "Masterials") {
      agenticTasks = ["Quick Download", "Create Brochure", "Create Story", "Create Blog", "Create Card"];
    } else if (category === "Portraits") {
      agenticTasks = ["Quick Download", "Create Portrait Look", "Create Backstory", "Create Story", "Create Script", "Create Cinematic Scene"];
    } else if (category === "Robots") {
      agenticTasks = ["Quick Download", "Create Robot Design", "Create Scifi Story", "Comic Book Page", "Create Movie Scene"];
    } else if (category === "Textures") {
      agenticTasks = ["Quick Download", "Create Texture Design", "Create Texture Swatch", "Create Story", "Create Texture Blog", "Create Texture Card"];
    } else if (category === "Toys") {
      agenticTasks = ["Quick Download", "Create Design", "Create Story", "Create Blog", "Create Show"];
    } else if (category === "Travel") {
      agenticTasks = ["Quick Download", "Travel Story", "Travel Blog", "Create Show", "Create Postcard"];
    } else if (category === "Transportation") {
      agenticTasks = ["Quick Download", "Create Design", "Create Story", "Create Blog", "Create Show"];
    } else if (category === "Urban") {
      agenticTasks = ["Quick Download", "Create Design", "Create Story", "Create Blog", "Create Show"];
    } else 
      agenticTasks = ["Quick Download", "Create Story", "Create Backstory", "Vary Image"];
    
      // return random item from the array
      let randomIndex = Math.floor(Math.random() * agenticTasks.length);      
      return agenticTasks[randomIndex];
  };
