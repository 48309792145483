import React, { useState, useEffect } from "react";
import ImageService from "../../services/image.service";
import ListService from "../../services/list.service";
import ProductService from "../../services/product.service";

import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";

import Searchbar from "../../components/searchbar/searchbar";
import { useAuth } from "../../services/auth/use-auth";
import UserService from "../../services/user.service";
import { determineAgenticTasks } from "../../utils/utilsTextFuncs";
import filterProfanity from "../../services/text.service";
import useGoogleOneTap from "../../hooks/useGoogleOneTap";
import { GoogleAuthProvider } from "firebase/auth";
import "./uploadDetail.css";
import s from "./uploadDetail.module.scss";
import {
  Button,
  Backdrop,
  Box,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Divider,
  IconButton,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import GetFriendlyUsername from "../../utils/utilsStringFuncs";
import TimeAgo from "react-timeago";

export default function UploadDetail() {
  const auth = useAuth();

  //const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState();
  const [featured, setFeatured] = useState([]);
  const [featuredText, setFeaturedText] = useState([]);
  const [page, setPage] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [authLoading, setAuthLoading] = useState(false);
  const [trendingSearches, setTrendingSearches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [favorites, setFavorites] = useState([]); // [image_id, ...
  const [products, setProducts] = useState([]);
  const [showMore, setShowMore] = useState(true);
  const [featureCategory, setFeatureCategory] = useState("Portraits");
  const [isMobileResolution, setIsMobileResolution] = useState(false);
  const [isTabletResolution, setIsTabletResolution] = useState(false);
  const [isDesktopResolution, setIsDesktopResolution] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileBytes, setFileBytes] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageResolution, setImageResolution] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDescribing, setIsDescribing] = useState(false);

  const DEFAULT_PAGE_SIZE = 24;

  // primary effect to fetch data
  useEffect(() => {
    let mounted = true;

    const fetchAllData = async () => {
      if (!auth.user) return;

      try {
        //setLoading(true);

        // Get initial category from URL
        const initialCategory = searchParams.get("q") || "All";

        // Parallel fetch for better performance
        const [categoriesResponse, productsResponse] = await Promise.all([
          ListService.GetCategories(),
          ProductService.GetProductList(
            auth.user.uid,
            auth.user.auth.currentUser.accessToken
          ),
        ]);

        if (mounted) {
          setSelectedCategory(initialCategory);
          setCategories(["All", "Uploads", ...categoriesResponse.sort()]);
          setProducts(productsResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        if (mounted) {
          //setLoading(false);
        }
      }
    };

    fetchAllData();

    return () => {
      mounted = false;
    };
  }, [auth.user, searchParams]); // Only dependencies that should trigger a refetch

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
        //setMeta(null);
      }
    };
  }, [previewUrl]);

  useEffect(() => {
    //determine different screen sizes using a library
    if (window.innerWidth <= 768) {
      setIsMobileResolution(true);
    }
    if (window.innerWidth > 768 && window.innerWidth <= 1024) {
      setIsTabletResolution(true);
    }
    if (window.innerWidth > 1024) {
      setIsDesktopResolution(true);
    }
  }, []);

  //
  const handleGoogleOneTapResponse = async (response) => {
    setAuthLoading(true);

    try {
      // // console.log(
      //   "SUCCESS: Signin with Google One Tap Success: ",
      //   response.credential
      // );
      // console.log("loading: ", loading);
      const credential = response.credential;
      // console.log("google one tap response: ", response);
      const googleAuthCredential = GoogleAuthProvider.credential(credential);
      // console.log("googleAuthCredential: ", googleAuthCredential);
      var result = await auth.signInWithCredentialFlow(googleAuthCredential);
      // console.log("result: ", result);
      var userDetails = auth.getAdditionalUserInfoFlow(result);
      // console.log("userDetails: ", userDetails);
      var currentUser = result.user;
      // console.log("Successfully created account using Google");
      const isNewUser = userDetails.isNewUser;
      if (isNewUser) {
        // console.log("Creating New user");
        const newUser = {
          userId: currentUser.uid,
          email: currentUser.email,
          password: null,
          displayName: currentUser.displayName,
          photoURL: currentUser.photoURL,
        };
        var createNewUserRes = await UserService.CreateNewUserAccount(newUser);
        // console.log(createNewUserRes);
        if (createNewUserRes?.userId) {
          navigate("/");
        } else {
          alert(
            "Failed to create new user - please contact support team or try another method."
          );
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      // console.log("ERROR: Signin with Google One Tap Error: ", error);
    }
    setAuthLoading(false);
    // console.log("loading: ", loading);
  };

  const handleGoogleButtonClick = (isShowing) => {
    if (isShowing) {
      setAuthLoading(true);
      // console.log("Google OneTap Button Clicked");
      // console.log("event: ", isShowing);
    } else {
      setAuthLoading(false);
    }
  };

  useGoogleOneTap({
    // onError: (error) => // console.log("Google OneTap Error: ", error),
    // onComplete: (response) => // console.log("Google OneTap Success: ", response),
    onDisplay: (isShowing) => handleGoogleButtonClick(isShowing),
    showPrompt: true,
    disabled: auth.user ? true : false,
    disableCancelOnUnmount: false,
    googleConfig: {
      auto_select: auth.user ? true : false,
      cancel_on_tap_outside: false,
      callback: handleGoogleOneTapResponse,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    },
  });

  const HeaderBanner = () => {
    return (
      <Box className="bg-hero px-4 py-5 text-center">
        <Typography
          variant="h3"
          className="display-5"
          color="lightyellow"
          sx={{
            fontFamily: "Raleway",
            fontWeight: "700",
          }}
        >
          Original AI-Generated stock images
        </Typography>
        {
          // do not show in mobile
          !isMobileResolution && (
            <div className="mx-auto">
              <Typography
                color="lightyellow"
                className="mb-4"
                sx={{
                  fontFamily: "Raleway",
                  fontSize: 20,
                  paddingTop: "12px",
                  fontWeight: "400",
                }}
              >
                Beautifully prompted, expertly curated, royalty-free images for
                all your digital projects
              </Typography>
              <Typography
                color="lightyellow"
                className="mb-4"
                sx={{
                  fontFamily: "Raleway",
                  fontStyle: "italic",
                  fontSize: 16,
                  fontWeight: "400",
                }}
              >
                smartai.studio - create. enhance. inspire.
              </Typography>
            </div>
          )
        }
        <Searchbar />
      </Box>
    );
  };

  const NotAuthenticatedBanner = () => {
    return (
      <div
        className="bg-banner px-4 text-center"
        title="Register to access AI Studio features"
        label="Register to access AI Studio features"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "400px",
          width: "100%",
        }}
      >
        <div className="d-flex align-items-center justify-content-center mx-auto py-2">
          <p className="mb-0 me-3 register-text">
            Register to access AI Studio features:
          </p>
          <div className="button" onClick={() => navigate("/free-signup")}>
            Join For Free
          </div>
        </div>
      </div>
    );
  };

  const getTopicsSection = (topics) => {
    return (
      auth.user &&
      topics &&
      topics.length > 0 && (
        <Box>
          <div className="fw-bold mb-2">Topics:</div>
          {topics.map((tag, index) => {
            return (
              <Chip
                key={`tags__${index}`}
                color="tertiary"
                sx={{ margin: 0.2 }}
                label={
                  <Typography
                    sx={{
                      margin: 1,
                      fontFamily: "Raleway",
                      fontSize: 12,
                    }}
                  >
                    {tag}
                  </Typography>
                }
              />
            );
          })}
        </Box>
      )
    );
  };

  const getTagsSection = (tags, title) => {
    return (
      auth.user &&
      tags &&
      tags.length > 0 && (
        <Box>
          <div className="fw-bold mb-2">{title}:</div>
          {tags.map((tag, index) => {
            return (
              <Chip
                key={`tags__${index}`}
                color="secondary"
                sx={{ margin: 0.2 }}
                label={
                  <Typography
                    sx={{
                      margin: 1,
                      fontFamily: "Raleway",
                      fontSize: 12,
                    }}
                  >
                    {tag}
                  </Typography>
                }
              />
            );
          })}
        </Box>
      )
    );
  };

  const handleImageUpload = (e) => {
    //const file = e.target.files[0];
    let file = null;
    if (e.target.files) {
      file = e.target.files[0];
    } else if (e.dataTransfer) {   
      file = e.dataTransfer.files[0];
      console.log("dropped file: ", e.dataTransfer.files[0]);
    }

    if (file === undefined || file === null) {
      console.error("No file selected");
      return;
    }
    
    
    if (file) {
      setPreviewUrl(URL.createObjectURL(file));
      setSelectedFile(file);
      //setMeta(null);
    } else {
      console.error("No file selected");
      return;
    }

    // Validate file size (max 10MB)
    const maxSize = 3 * 1024 * 1024; // 10MB in bytes
    if (file.size > maxSize) {
      alert("File is too large. Maximum size is 3MB");
      return;
    }

    // Get bytes from file
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        setLoading(true);
        const bytes = new Uint8Array(e.target.result);

        // Now you have both the file and bytes
        setSelectedFile(file);
        setFileBytes(bytes);

        // get height and width of image
        const img = new Image();
        const previewUrl = URL.createObjectURL(file);
        img.src = previewUrl;
        img.onload = function () {
          console.log("Image width: ", this.width);
          console.log("Image height: ", this.height);

          setImageWidth(this.width);
          setImageHeight(this.height);
          setImageResolution(`${this.width} x ${this.height}`);          
        };

        // Optional: Preview image        
        setPreviewImage(previewUrl);
      } catch (error) {
        console.error("Error reading file:", error);
      }
    };

    reader.readAsArrayBuffer(file);
    
    setLoading(false);
  };

  const handleDescribeImage = () => {
    if (!selectedFile) {
      return;
    }

    setIsDescribing(true);

    // Get bytes from file
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const bytes = new Uint8Array(e.target.result);
        console.log("image length: ", bytes.length, " bytes");

        // Convert to base64 string
        const base64String = btoa(
          new Uint8Array(e.target.result).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        //console.log("base64String: ", base64String.length, " bytes");

        ImageService.DescribeImage(
          auth.user.uid,
          base64String,
          base64String,
          auth.user.auth.currentUser.accessToken
        ).then((response) => {
          //navigate(`/image/${response.id}`);
          console.log("describe response: ", response);
          response.width = imageWidth;
          response.height = imageHeight;
          response.resolution = imageResolution;

          setMeta(response);
          setIsDescribing(false);
        });
      } catch (error) {
        console.error("Error describing file:", error);
      }
    };

    try {
      reader.readAsArrayBuffer(selectedFile);
    } catch (error) {
      console.error("Failed to describe image: ", error);
    }
  };

  const ImageCard = (currentMeta) => {
    console.log("meta: ", currentMeta);
    // Optional: Preview image
    const dataUrl = URL.createObjectURL(selectedFile);

    return currentMeta ? (
      <Card
        className=""
        sx={{
          boxShadow: "none",
          borderRadius: "10px",
          border: "1px solid #e0e0e0",
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <CardHeader
          sx={{
            borderRadius: "10px 10px 0 0",
          }}
          title={
            <Box
              className="card__header__top"
              sx={{
                padding: "1px",
                display: "flow-root",
                justifyContent: "space-between",
                flexFlow: "row wrap",
              }}
            >
              <Chip
                sx={{
                  margin: 0.4,
                  borderRadius: "5px",
                  padding: "4px",
                }}
                color="secondary"
                label={
                  <Typography>{" " + currentMeta.category + " "}</Typography>
                }
              />

              <LoadingButton                
                sx={{
                  margin: 0.2,
                  borderRadius: "5px",
                }}
                variant="contained"
                onClick={() => {
                  //navigator.clipboard.writeText(JSON.stringify(currentMeta));
                  // copy text to clipboard in formatted text
                  navigator.clipboard.writeText(
                    JSON.stringify(currentMeta, null, 2)
                  );
                }}
              >
                <FontAwesomeIcon size="lg" color="#666" icon={faCopy} /> &nbsp;
                Copy Metadata
              </LoadingButton>
              <LoadingButton
                loadingIndicator="Submitting..."
                loading={isSubmitting}
                sx={{
                  margin: 0.2,
                  borderRadius: "5px",
                }}
                variant="contained"
                onClick={() => {
                  submitImageUpload();
                }}
              >
                <FontAwesomeIcon size="lg" color="#666" icon={faUpload} />{" "}
                &nbsp; Upload Gallery
              </LoadingButton>
            </Box>
          }
          action={<IconButton aria-label="settings"></IconButton>}
        ></CardHeader>
        <CardMedia
          className="img-responsive img-thumbnail"
          sx={{
            padding: 0,
          }}
          component="img"
          image={dataUrl}
          alt={data.description}
        />
        <CardContent
          sx={{
            borderRadius: "0 0 10px 10px",
            padding: "10px",
            width: "100%",
          }}
        >
          <Box
            className="mt-3"
            sx={{
              padding: "10px",
            }}
          >
            <Typography
              variant="h6"
              color="text.primary"
              sx={{
                fontFamily: "Raleway",
                fontWeight: "700",
                fontSize: "1.5rem",
                padding: "10px",
              }}
            >
              <strong>Title: </strong>
              {currentMeta.title}
            </Typography>
            <br />
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                fontFamily: "Raleway",
                fontSize: "1rem",
                fontWeight: "400",
                marginBottom: "10px",
              }}
            >
              <strong>Description: </strong>
              {currentMeta.description}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                fontFamily: "Raleway",
                fontSize: "1rem",
                fontWeight: "400",
                marginBottom: "10px",
              }}
            >
              <strong>Prompt: </strong>
              {currentMeta.revisedPrompt}
            </Typography>
          </Box>

          <Box className="mt-3">
            {currentMeta.topics ? getTopicsSection(currentMeta.topics) : <></>}
          </Box>
          <Box className="mt-3">
            {currentMeta.tags ? (
              getTagsSection(currentMeta.tags, "Tags")
            ) : (
              <></>
            )}
          </Box>
          <Box className="mt-3">
            {currentMeta.colors ? (
              getTagsSection(currentMeta.colors, "Colors")
            ) : (
              <></>
            )}
          </Box>
          <Box className="mt-3">
            {currentMeta.materials ? (
              getTagsSection(currentMeta.materials, "Materials")
            ) : (
              <></>
            )}
          </Box>
          <Box className="mt-3">
            {currentMeta.styles ? (
              getTagsSection(currentMeta.styles, "Styles")
            ) : (
              <></>
            )}
          </Box>
        </CardContent>
      </Card>
    ) : (
      <></>
    );
  };

  const submitImageUpload = (e) => {
    if (!selectedFile) {
      return;
    }
    if (!meta) {
      return;
    }

    setIsSubmitting(true);

    // Get bytes from file
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const bytes = new Uint8Array(e.target.result);
        console.log("image length: ", bytes.length, " bytes");

        // Convert to base64 string
        const base64String = btoa(
          new Uint8Array(e.target.result).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );

        ImageService.UploadImage(
          auth.user.uid,
          base64String,
          null,
          meta,
          auth.user.auth.currentUser.accessToken
        ).then((response) => {
          console.log("upload response: ", response);
          if (response.success) { navigate("/profile"); }
        });
      } catch (error) {
        console.error("Error uploading file:", error);
        return;
      } finally {
        setIsSubmitting(false);
      }
    };

    try {
      reader.readAsArrayBuffer(selectedFile);
    } catch (error) {
      console.error("Failed to upload image: ", error);
    }
  };

  const SubmitImageButton = () => {
    return (
      <LoadingButton
        variant="contained"
        color="primary"
        disabled={!selectedFile}
        onClick={() => submitImageUpload()}
        sx={{
          borderRadius: "12px",
          padding: "5px",
          margin: "5px",
          fontSize: "14px",
          fontWeight: "400",
          width: "300px",
          textTransform: "none",
        }}
      >
        Upload Image to Gallery
      </LoadingButton>
    );
  };
  const DescribeImageButton = () => {
    return (
      <LoadingButton
        loadingIndicator="Describing..."
        loading={isDescribing}
        variant="contained"
        color="primary"
        disabled={!selectedFile}
        onClick={() => handleDescribeImage()}
        sx={{
          borderRadius: "12px",
          padding: "5px",
          margin: "5px",
          fontSize: "14px",
          fontWeight: "400",
          width: "300px",
          textTransform: "none",
        }}
      >
        ✨Describe Image Agent (1)
      </LoadingButton>
    );
  };

  const renderUploadImage = () => {
    console.log("selectedFile: ", selectedFile);
    console.log("previewUrl: ", previewUrl);
    console.log("meta: ", meta);

    return meta === undefined && selectedFile ? (
      <div
        className={s.container__box}
        style={{
          borderRadius: "6px",
          border: "1px solid #8d8d8d",
          backgroundColor: "#EAEAEA",
          //maxWidth: `${(data.width / 2)}px`,
          margin: "auto",
          padding: "10px",
        }}
      >
        <div
          className="image-upload-area"
          style={{
            position: "relative",
            minHeight: "200px",
            border: "2px dashed #ccc",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {selectedFile ? (
            <img
              className={s.container__box__image}
              src={previewImage}
              alt="Preview"
              style={{
                borderRadius: "6px 6px 0 0",
                width: "100%",
                border: "2px solid #8BA0A4",
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.1)",
              }}
            />
          ) : (
            <Typography
              variant="body1"
              color="text.secondary"
              className="my-2"
              sx={{
                fontFamily: "Raleway",
                fontSize: 16,
                fontWeight: 500,
                padding: "10px",
                textAlign: "center",
              }}
            >
              Drag and drop an image here or click to select
            </Typography>
          )}
        </div>

        {//SubmitImageButton()
        }
        {DescribeImageButton()}
        <Typography
          variant="body2"
          color="text.secondary"
          className="my-2"
          sx={{
            fontFamily: "Raleway",
            fontSize: 16,
            fontWeight: 500,
            padding: "10px",
            textAlign: "center",
          }}
        >
          {data.title}
        </Typography>
        <Typography
          color="text.secondary"
          className="my-2"
          sx={{
            fontFamily: "Raleway",
            fontSize: 12,
            fontStyle: "italic",
            fontWeight: 300,
            textAlign: "center",
          }}
        >
          NOTE: Do not submit images larger than 3MB or with a resolution
          greater than 1280x1280 pixels resolution. <br />
          Please ensure you have the rights to submit the image, and that it does not contain any offensive or inappropriate content. 
          Please review our <a target="_blank" href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce">terms of service</a> for more information.
        </Typography>
      </div>
    ) : (
      <></>
    );
  };
  const ShowImageUploadArea = () => {
    return (
      <Box
        id="image-upload-area"
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          handleImageUpload(e);
          //console.log("dropped file: ", e.dataTransfer.files[0]);
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          minWidth: "400px",
          minHeight: "400px",
          width: "100%",
          height: "80%",
          border: "1px dashed #ccc",
          borderRadius: "10px",
          backgroundColor: "#f0f0f0", 
        }}        
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Raleway",
            fontWeight: "700",
            fontSize: "1.5rem",
            textAlign: "center",
            margin: "10px",
          }}
        >
          Upload your image
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Raleway",
            fontSize: "1rem",
            textAlign: "center",
            marginBottom: "25px",
          }}
        >
          Drag & Drop an image (or Choose File) to get started.
        </Typography>
        <input
          type="file"
          accept="image/*"          
          onChange={(e) => handleImageUpload(e)}
          sx={{
            margin: "10px",
            padding: "10px",
            borderRadius: "10px",
            border: "1px solid #ccc",
          }}
        />
        {selectedFile ? renderUploadImage() : <></>}
        {meta ? ImageCard(meta) : <></>}
      </Box>
    );
  };

  const ShowSpinner = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "10vh",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  };

  return (
    <div
      className="home"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        minWidth: "400px",
        width: "100%",
        minHeight: "800px",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={authLoading}
      >
        { loading ? ShowSpinner() : <></> }
      </Backdrop>
      {HeaderBanner()}
      {!auth.user ? NotAuthenticatedBanner() : <></>}
      
      {ShowImageUploadArea()}

        <p />
      
    </div>
  );
}
