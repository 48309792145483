import React, { useState, useEffect } from "react";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import MultiDropdown from "../../components/multi-dropdown/MultiDropdown";
import Tiles from "../../components/Tiles/Tiles2";
import ImageService from "../../services/image.service";
import ListService from "../../services/list.service";
import { useAuth, EnsureToken } from "../../services/auth/use-auth";
import { LogSomething } from "../../utils/utilsStringFuncs";
import filterProfanity from "../../services/text.service";
import searchIcon from "../../assets/img/searchIcon.png";
import "./Search.scss";

import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  Link,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Check } from "@mui/icons-material";
import Modal from "../../components/modal/modal";
import ProductService from "../../services/product.service";
import { LoadingButton } from "@mui/lab";
import { useEffectOnce } from "../../utils/useEffectOnce";
import Searchbar from "../../components/searchbar/searchbar";
import colorsList from "../../services/list.data";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
  deviceType,
  mobileVendor,
  mobileModel,
  getUA,
  isDesktop,
  isMobile,
  isTablet,
} from "react-device-detect";

export default function Search2() {
  const auth = useAuth();
  const [init, setInit] = useState(true);
  //const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [props, setProps] = useState({
    noPerRow: 4,
    gap: 30,
    showQuickActions: true,
  });
  const [props2, setProps2] = useState({
    noPerRow: 5,
    gap: 20,
    height: 100,
  });
  const [page, setPage] = useState(0);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [colors, setColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [materials, setMaterials] = useState([]);
  const [heights, setHeights] = useState([]);
  const [selectedHeight, setSelectedHeight] = useState("");
  const [widths, setWidths] = useState([]);
  const [selectedWidth, setSelectedWidth] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [lastSearchTerm, setLastSearchTerm] = useState("");
  const [noOfSearch, setNoOfSearch] = useState(0);
  const [pricingModal, setPricingModal] = useState(false);
  const [relatedSearches, setRelatedSearches] = useState([]);
  const [trendingSearches, setTrendingSearches] = useState([]);
  const [featured, setFeatured] = useState([]);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBarTerm, setSearchBarTerm] = useState("");

  
  
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px)");
  const isDesktop = useMediaQuery("(min-width:960px)");
  const isLargeDesktop = useMediaQuery("(min-width:1200px)");
  const isExtraLargeDesktop = useMediaQuery("(min-width:1400px)");


  const [previousNumResults, setPreviousNumResults] = useState(null);
  const MAX_RESULTS = 25;
  const MAX_OVERALL_RESULTS = 100;
  const MAX_NUM_PAGED = isMobile ? 3 : 5;
  const MAX_RESULTS_ANON = 5;
  const MAX_SEARCHES_ANONYMOUS = 5;

  const style = {
    bgColor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

/* USE EFFECT =============== GET PRODUCTS & CATEGORIES INFO  */
useEffect(() => {
  let mounted = true;
  setLoading(true);
  /*
  LogSomething(
    "useEffect() - PRODUCTS & CATEGORIES",
    "auth.user: ",
    JSON.stringify(auth.user)
  );
  */

  if (mounted) {
    const getProductsAndCategories = async () => {
      if (auth.user) {
        //console.log("getFeatured: input category(",JSON.stringify(inputCategory),") feature category(",JSON.stringify(featureCategory),")");
        try {
          ProductService.GetProductList(
            auth.user.uid,
             await EnsureToken(auth.user.auth.currentUser)
          ).then((response) => {
            LogSomething(
              "useEffect() - PRODUCTS: " + JSON.stringify(response)
            );
            setProducts(response);
          });
        } catch (error) {
          LogSomething(
            "useEffect() - PRODUCTS - ERROR - : ",
            error
          );
        }
      } else {
        LogSomething("useEffect() - PRODUCTS - no auth.user");
      }
    };

    getProductsAndCategories();
    setLoading(false);
  }

  return () => {
    mounted = false;
  };
}, [auth.user]);







  const loadMore = async () => {
    console.log(
      "loadMore() - page: ",
      page,
      " searchTerm: ",
      searchTerm,
      " selectedCategory: ",
      selectedCategory,
      " selectedTopic: ",
      selectedTopic,
      " selectedColor: ",
      selectedColor,
      " selectedHeight: ",
      selectedHeight,
      " selectedWidth: ",
      selectedWidth
    );

    if (selectedCategory !== "") {
      if (selectedTopic !== "") {
        runSearch2("topic", selectedTopic, page + 1, selectedCategory);
      } else if (selectedColor !== "") {
        runSearch2("color", selectedColor, page + 1, selectedCategory);
      } else if (selectedHeight !== "") {
        runSearch2("height", selectedHeight, page + 1, selectedCategory);
      } else if (selectedWidth !== "") {
        runSearch2("width", selectedWidth, page + 1, selectedCategory);
      } else {
        runSearch2("category", selectedCategory, page + 1);
      }
    } else {
      if (selectedTopic !== "") {
        runSearch2("topic", selectedTopic, page + 1);
      } else if (selectedColor !== "") {
        runSearch2("color", selectedColor, page + 1);
      } else if (selectedHeight !== "") {
        runSearch2("height", selectedHeight, page + 1);
      } else if (selectedWidth !== "") {
        runSearch2("width", selectedWidth, page + 1);
      } else {
        runSearch2("category", selectedCategory, page + 1);
      }
    }

    if (
      searchTerm !== "" &&
      selectedCategory === "" &&
      selectedTopic === "" &&
      selectedColor === "" &&
      selectedHeight === "" &&
      selectedWidth === ""
    ) {
      runSearch2("", searchTerm, page + 1);
    }

    setPage(page + 1);
    //runSearch2("", searchTerm, page + 1);
  };

  const download = async (url) => {
    try {
      var urlDownload = url ? url : data.public_url_reduced;
      console.log("downloadImage: url(" + url + ")");
      console.log("downloadImage: urlDownload(" + urlDownload + ")");
      const blob = await fetch(urlDownload).then((r) => r.blob());
      const urlBlob = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = urlBlob;
      link.target = "_blank";
      link.download =
        "SmartAI_Studio_" +
        urlDownload.substr(urlDownload.lastIndexOf("/") + 1);
      link.click();
    } catch (error) {
      console.log("downloadImage: error(" + error + ")");
    }
  };

  const handleQuickDownload = async (imageId, featuredText) => {
    LogSomething(
      "handleQuickDownload() - entered",
      imageId,
      " user: ",
      JSON.stringify(auth.user)
    );
    // get the product
    const selectedProduct = products.find((product) => product.productId === 1);
    setLoading(true);

    //if (auth.user && acceptTerms && auth.user.id && identifier !== "") {
    if (
      auth.user &&
      imageId !== "" &&
      selectedProduct &&
      selectedProduct.productId > 0
    ) {
      LogSomething(
        "handleQuickDownload() - Passed preconditions",
        imageId,
        ", product #",
        selectedProduct.productId,
        " user: ",
        JSON.stringify(auth.user)
      );
      var userId = auth.user.uid;
      var token =  await EnsureToken(auth.user.auth.currentUser);
      const response = await ProductService.PurchaseImageUsingCredits(
        userId,
        token,
        selectedProduct.productId,
        imageId
      );

      if (response.success === false) {
        alert("Download was not successful: " + response.message);
        // console.log("purchaseImage: " + response);
      } else if (response.success === true) {
        // console.log("downloadImage: " + response.product.downloadUrl);
        // console.log("downloadImage: " + response.product.assetUrl);
        if (!browserName.includes("Safari")) {
          download(response.product.downloadUrl);
        }

        setLoading(false);
        if (featuredText !== undefined && featuredText !== null) {
          navigate("/studio?id=" + imageId + "&task=" + featuredText);
        } else navigate("/studio?id=" + imageId);
      }
    } else {
      console.log(
        "purchaseImage() - Failed check: id #",
        imageId,
        ", product #",
        selectedProduct
      );
    }

    setLoading(false);
  };


  /*
  const getFeatured = async () => {
    //if (auth.user) {
    //console.log("useEffect: auth.uid: ", JSON.stringify(auth.user.uid));
    if (featured.length > 0) return;

    ImageService.GetFeaturedImages(auth.user.uid, 0, 25, auth.user.auth.currentUser.accessToken).then((response) => {
      //console.log("useEffect: GetFavorites call: ", response);
      // get 5 random images from the response
      let randomized = [];
      for (let i = 0; i < 5; i++) {
        let index = Math.floor(Math.random() * response.length);
        randomized.push(response[index]);
      }

      setFeatured(randomized);
    });
    //}
  };
  */

  const handleCategoryChange = async (e) => {
    let newCategoryChosen = e.target.value !== selectedCategory;
    if (newCategoryChosen) {
      setPage(0);
      setData([]);
    }
    setLoading(true);
    setSelectedCategory(e.target.value);
    let filteredTopics = await ListService.GetTopicsForCategory(e.target.value);

    setTopics(filteredTopics);

    // reset combo boxes
    setSelectedTopic("");
    //setSelectedTag("");
    setSelectedColor("");
    setSelectedHeight("");
    setSelectedWidth("");

    let _page = newCategoryChosen ? 0 : page;
    runSearch2("category", e.target.value, _page, e.target.value);

    // scroll to results section
    document.getElementById("search__result").scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleTopicChange = (e) => {
    let newTopicChosen = e.target.value !== selectedTopic;
    if (newTopicChosen) {
      setPage(0);
      setData([]);
    }
    setLoading(true);
    setSelectedTopic(e.target.value);

    // reset combo boxes
    //setSelectedCategory("");
    //setSelectedTag("");

    setSelectedColor("");
    setSelectedHeight("");
    setSelectedWidth("");

    if (
      selectedCategory === "" ||
      selectedCategory === undefined ||
      selectedCategory === null
    ) {
      setSelectedCategory("");
    }
    let _page = newTopicChosen ? 0 : page;
    runSearch2("topic", e.target.value, _page, selectedCategory);
  };

  /*
    const handleTagChange = (e) => {
        if (e.target.value !== selectedTag) {
            setPage(0);
            setData([]);
        }
        setLoading(true);
        setSelectedTag(e.target.value);

        // reset combo boxes
        setSelectedCategory("");
        setSelectedTopic("");
        setSelectedColor("");
        setSelectedHeight("");
        setSelectedWidth("");
        

        runSearch2("tag",e.target.value);
    };
*/
  const handleColorChange = (e) => {
    let newColorChosen = e.target.value !== selectedColor;
    if (newColorChosen) {
      setPage(0);
      setData([]);
    }

    let hex = e.target.value["hex"].trim();
    let name = e.target.value["name"].trim();

    setLoading(true);
    setSelectedColor(name);

    // reset combo boxes
    //setSelectedCategory("");

    setSelectedTag("");
    setSelectedHeight("");
    setSelectedWidth("");
    setSelectedTopic("");

    if (
      selectedCategory === "" ||
      selectedCategory === undefined ||
      selectedCategory === null
    ) {
      setSelectedCategory("");
    }

    let _page = newColorChosen ? 0 : page;
    runSearch2("color", name, _page, selectedCategory, selectedTopic);
  };

  const handleHeightChange = (e) => {
    let newHeightChosen = e.target.value !== selectedHeight;
    if (newHeightChosen) {
      setPage(0);
      setData([]);
    }
    setLoading(true);
    setSelectedHeight(e.target.value);

    // reset combo boxes

    setSelectedTopic("");
    setSelectedTag("");
    setSelectedColor("");
    setSelectedWidth("");

    if (
      selectedCategory === "" ||
      selectedCategory === undefined ||
      selectedCategory === null
    ) {
      setSelectedCategory("");
    }
    let _page = newHeightChosen ? 0 : page;
    runSearch2(
      "height",
      e.target.value,
      _page,
      selectedCategory,
      selectedTopic
    );
  };

  const handleWidthChange = (e) => {
    let newWidthChosen = e.target.value !== selectedWidth;
    if (newWidthChosen) {
      setPage(0);
      setData([]);
    }
    setLoading(true);
    setSelectedWidth(e.target.value);

    setSelectedTopic("");
    setSelectedTag("");
    setSelectedColor("");
    setSelectedHeight("");

    if (
      selectedCategory === "" ||
      selectedCategory === undefined ||
      selectedCategory === null
    ) {
      setSelectedCategory("");
    }
    let _page = newWidthChosen ? 0 : page;
    runSearch2("width", e.target.value, _page, selectedCategory, selectedTopic);
  };

  const pricingModalClose = () => {
    setPricingModal(false);
    // navigate to login page
    navigate("/login");
  };

  const runSearch2 = async (
    tagName,
    q,
    pageNum,
    categoryFilter = "",
    topicFilter = ""
  ) => {
    if (q === "") {
      return;
    }

    console.log(
      "runSearch2() - tagName(",
      tagName,
      ") q(",
      q,
      ") pageNum(",
      pageNum,
      ") categoryFilter(",
      categoryFilter,
      ") topicFilter(",
      topicFilter,
      ")"
    );

    // check if q is a string, if so, check if it has a trailing dash, if so remove it
    if (typeof q === "string" && q.endsWith("-")) {
      q = q.substring(0, q.length - 1);
    }

    //setShowMore(true);
    setSearchTerm(q);

    /*
    if (lastSearchTerm !== q) {
      pageNum = 0;
      setPage(0);
    }
    
    //setSearchParams(createSearchParams({ q }));
    */

    // preconditions
    if (pageNum === 0) {
      setData([]);
    }
    if (pageNum === undefined) {
      pageNum = 0;
    }
    if (pageNum === null) {
      pageNum = 0;
    }
    if (pageNum < 0) {
      pageNum = 0;
    }
    if (isNaN(pageNum)) {
      pageNum = 0;
    }

    // set loading
    setInit(false);
    if (pageNum === 0) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }

    var results = [];

    if (tagName === "SearchByGenericTag") {
      let tagTokens = q.split("-");
      let tagValue = tagTokens[1];

      tagName = tagTokens[0];
      q = tagValue;
    }

    switch (tagName) {
      case "category" || "SearchByCategory":
        console.log("runSearch - SearchByCategory:", q);
        results = ImageService.GetImagesByCategory(
          auth.user.uid,
          q,
          auth.user ? pageNum : 0,
          auth.user ? MAX_RESULTS : MAX_RESULTS_ANON,
          await EnsureToken(auth.user.auth.currentUser)
        ).then((response) =>
          processResponse(selectedCategory, selectedCategory, response)
        );
        break;
      case "topic" || "SearchByTopic":
        if (categoryFilter !== "") {
          console.log(
            "runSearch - GetImagesByCategoryAndTopic:",
            categoryFilter,
            ":" + q
          );
          results = ImageService.GetImagesByCategoryAndTopic(
            auth.user.uid,
            categoryFilter,
            q,
            auth.user ? pageNum : 0,
            auth.user ? MAX_RESULTS : MAX_RESULTS_ANON,
            await EnsureToken(auth.user.auth.currentUser)
          ).then((response) => processResponse(categoryFilter, q, response));
        } else {
          console.log("runSearch - GetImagesByTopic:", q);
          results = ImageService.GetImagesByTopic(
            auth.user.uid, 
            q, 
            pageNum, 
            MAX_RESULTS, 
            await EnsureToken(auth.user.auth.currentUser)).then(
            (response) => processResponse("", q, response)
          );
        }
        break;
      case "tag" || "SearchByGenericTag":
        let tagTokens = q.split("-");
        let tagValue = tagTokens[1];
        if (categoryFilter !== "") {
          console.log(
            "runSearch - GetImagesByCategoryAndTag:",
            categoryFilter,
            ":" + tagValue
          );
          results = ImageService.GetImagesByCategoryAndTag(
            auth.user.uid,
            categoryFilter,
            tagValue,
            auth.user ? pageNum : 0,
            auth.user ? MAX_RESULTS : MAX_RESULTS_ANON,
            await EnsureToken(auth.user.auth.currentUser)            
          ).then((response) => processResponse(categoryFilter, q, response));
        } else {
          console.log("runSearch - GetImagesByTag:", tagValue);
          results = ImageService.GetImagesByTag(
            auth.user.uid,
            tagValue,
            auth.user ? pageNum : 0,
            auth.user ? MAX_RESULTS : MAX_RESULTS_ANON,
            await EnsureToken(auth.user.auth.currentUser)
          ).then((response) => processResponse("", q, response));
        }
        break;

      case "color" || "SearchByColor":
        if (categoryFilter !== "") {
          console.log(
            "runSearch - GetImagesByCategoryAndColor:",
            categoryFilter,
            ":" + q
          );
          results = ImageService.GetImagesByCategoryAndColor(
            auth.user.uid,
            categoryFilter,
            q,
            auth.user ? pageNum : 0,
            auth.user ? MAX_RESULTS : MAX_RESULTS_ANON,
            await EnsureToken(auth.user.auth.currentUser)
          ).then((response) => processResponse(categoryFilter, q, response));
        } else {
          console.log("runSearch - GetImagesByColor:", q);
          results = ImageService.GetImagesByColor(
            auth.user.uid,
            q, 
            pageNum, 
            MAX_RESULTS, 
            await EnsureToken(auth.user.auth.currentUser)).then(
            (response) => processResponse("", q, response)
          );
        }
        break;
      case "height" || "SearchByHeight":
        if (categoryFilter !== "") {
          console.log(
            "runSearch - GetImagesByCategoryAndHeight:",
            categoryFilter,
            ":" + q
          );
          results = ImageService.GetImagesByCategoryAndHeight(
            auth.user.uid,
            categoryFilter,
            q,
            auth.user ? pageNum : 0,
            auth.user ? MAX_RESULTS : MAX_RESULTS_ANON,
            await EnsureToken(auth.user.auth.currentUser)
          ).then((response) => processResponse(categoryFilter, q, response));
        } else {
          console.log("runSearch - GetImagesByHeight:", q);
          results = ImageService.GetImagesByHeight(
            auth.user.uid,
            q,
            auth.user ? pageNum : 0,
            auth.user ? MAX_RESULTS : MAX_RESULTS_ANON,
            await EnsureToken(auth.user.auth.currentUser)
          ).then((response) => processResponse("", q, response));
        }
        break;

      case "width" || "SearchByWidth":
        if (categoryFilter !== "") {
          console.log(
            "runSearch - GetImagesByCategoryAndWidth:",
            categoryFilter,
            ":" + q
          );
          results = ImageService.GetImagesByCategoryAndWidth(
            auth.user.uid,
            categoryFilter,
            q,
            auth.user ? pageNum : 0,
            auth.user ? MAX_RESULTS : MAX_RESULTS_ANON,
            await EnsureToken(auth.user.auth.currentUser)            
          ).then((response) => processResponse(categoryFilter, q, response));
        } else {
          console.log("runSearch - GetImagesByWidth:", q);
          results = ImageService.GetImagesByWidth(
            auth.user.uid,
            q,
            auth.user ? pageNum : 0,
            auth.user ? MAX_RESULTS : MAX_RESULTS_ANON,
            await EnsureToken(auth.user.auth.currentUser)
          ).then((response) => processResponse("", q, response));
        }
        break;

      default:
        console.log("runSearch - q:", q);
        if (q === "") {
          return;
        }

        // stringify q if numeric
        if (!isNaN(q)) {
          q = q.toString();
        }

        // if search term contains quotes, search for exact match
        if (q.includes('"')) {
          console.log("runSearch - GetImagesByPhraseSearchTerm:", q);
          results = ImageService.GetImagesByPhraseSearchTerm(
            auth.user.uid,
            q,
            auth.user ? pageNum : 0,
            auth.user ? MAX_RESULTS : MAX_RESULTS_ANON,
            await EnsureToken(auth.user.auth.currentUser)
          ).then((response) => processResponse("", q, response));
        } else {
          console.log("runSearch - GetImagesBySearchTerm:", q);
          results = ImageService.GetImagesBySearchTerm(
            auth.user.uid,
            q,
            auth.user ? pageNum : 0,
            auth.user ? MAX_RESULTS : MAX_RESULTS_ANON,
            await EnsureToken(auth.user.auth.currentUser)
          ).then((response) => processResponse("", q, response));
        }
        break;
    }

    //setPage(pageNum);
    setShowMore(results.length >= MAX_RESULTS && pageNum < MAX_NUM_PAGED);
    // scroll to results section
    document.getElementById("search__result").scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleTrendingSearch = (interaction, meta1, meta2) => {
    console.log(
      "handleTrendingSearch() - interaction: ",
      interaction,
      "meta1: ",
      meta1,
      "meta2: ",
      meta2
    );
    // reset combo boxes
    setSelectedCategory("");
    setSelectedTopic("");
    //setSelectedTag("");
    setSelectedColor("");
    setSelectedHeight("");
    setSelectedWidth("");

    if (meta2 === undefined || meta2 === null || meta2 === "") {
      runSearch2(interaction, meta1, 0);
    } else {
      runSearch2(interaction, meta1 + "-" + meta2, 0);
    }
  };

  /* SearchBySearchTerm = 1,
 SearchByCategory = 2,
 SearchByTopic = 3,
 SearchByColor = 4,       
 SearchByMaterials = 5,
 SearchByFabrics = 6,
 SearchByGenericTag = 7,
 SearchByStyles = 8,
 SearchByTheme = 9,
 ImageInteraction = 15, // like, dislike, view, download
 ImageAddToGallery = 16,             
 GenerateText = 20,
 GenerateImage = 21,
 VaryImage = 22,
 ViewImage = 23,
 ViewImageGallery = 24,
 ViewTextGallery = 25,
 */

  function getFriendlyInteractionName(name) {
    //console.log("getFriendlyName() - name: ", name);
    let clean = "";

    if (name === "SearchByCategory") {
      clean = "Category";
    } else if (name === "SearchBySearchTerm") {
      clean = "Search";
    } else if (name === "SearchByGenericTag") {
      clean = "Tag";
    } else if (name === "SearchByTopic") {
      clean = "Topic";
    } else if (name === "SearchByColor") {
      clean = "Color";
    }

    //console.log("getFriendlyName() - -name: ", name, "clean: ", clean);
    return clean;
  }

  function getCapitalizedName(name) {
    if (name === undefined || name === null || name === "") {
      return "";
    }

    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  const renderTrendingSearches = () => {
    // now render results
    return (
      <Box className="search__result" sx={{ marginTop: "50px" }}>
        <Typography
          sx={{
            margin: 1,
            fontFamily: "Raleway",
            fontSize: 22,
            fontWeight: "bold",
            marginTop: "2px",
          }}
        >
          Trending Searches
        </Typography>
        <div className="searchbar search__result__container">
          {trendingSearches.map((item, index) => (
            <Button
              key={index}
              label={
                item.interaction !== "SearchByCategory"
                  ? getCapitalizedName(item.meta1) + " - " + item.meta2
                  : getFriendlyInteractionName(item.interaction) +
                    " - " +
                    item.meta1
              }
              title={
                item.interaction !== "SearchByCategory"
                  ? getCapitalizedName(item.meta1) + " - " + item.meta2
                  : getFriendlyInteractionName(item.interaction) +
                    " - " +
                    item.meta1
              }
              style={{
                // make button look like cool chip using primary color
                backgroundColor: "#e0e0e0",

                borderRadius: "20px",
                color: "#000000",
                padding: "5px 10px",
                margin: "5px",
                fontSize: "14px",
                fontWeight: "bold",
                textTransform: "none",
              }}
              onClick={() =>
                handleTrendingSearch(item.interaction, item.meta1, item.meta2)
              } // runSearch2(item.interaction, item.meta1, 0)}
            >
              <Link
                className="text-decoration-none"
                to={`/search?q=${item.meta1}`}
                state={{ category: item.meta1 }}
              >
                <Typography
                  // center content and apply padding
                  sx={{
                    color: "var(--primary-color)",
                    margin: 1,
                    padding: "2px",
                    fontFamily: "Raleway",
                    fontSize: 14,
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  <i
                    style={{
                      marginRight: "15px",
                      fontSize: "16px",
                      color: "var(--secondary-color)",
                    }}
                    className="fa-solid fa-magnifying-glass"
                  ></i>
                  {item.interaction !== "SearchByCategory"
                    ? getCapitalizedName(item.meta1) + " - " + item.meta2
                    : getFriendlyInteractionName(item.interaction) +
                      " - " +
                      item.meta1}
                </Typography>
              </Link>
            </Button>
          ))}
        </div>
      </Box>
    );
  };

  const getRelatedSearches = () => {
    // now render results
    return (
      <Box className="search__result">
        <Typography
          sx={{
            margin: 1,
            fontFamily: "Raleway",
            fontSize: 22,
            fontWeight: "bold",
            marginTop: "10px",
          }}
        >
          Related Searches
        </Typography>
        <div className="search__result__container">
          {relatedSearches.map((item, index) => (
            <Chip
              key={index}
              label={item}
              title={item.split(":")[1]}
              onClick={() =>
                runSearch2(item.split(":")[0], item.split(":")[1], 0)
              }
              style={{
                margin: "5px",
                width: "160px",
                height: "30px",
                fontSize: "14px",
              }}
            />
          ))}
        </div>
      </Box>
    );
  };

  const processResponse = (category, q, response) => {
    console.log(
      "runSearch2 - processResponse() - page(",
      page,
      ") category(",
      category,
      ") q(",
      q,
      ") response: ",
      response
    );

    if (
      response === undefined ||
      response === null ||
      !response ||
      typeof response[Symbol.iterator] !== "function"
    ) {
      setShowMore(false);
      setPreviousNumResults(0);
      setLoading(false);
      setLoadingMore(false);
      return;
    }

    if (page === 0) {
      setData([...response]);
    } else {
      setData((currentData) => [...currentData, ...response]);
    }

    if (response.length < MAX_RESULTS && page < MAX_NUM_PAGED) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }

    setPreviousNumResults(response.length);
    setSearchTerm(q);
    //setPage(page + 1);
    setLoading(false);
    setLoadingMore(false);
    /*
    if (page === 0) {
      setLoading(false);
    } else {
      setLoadingMore(false);
    }
    */

    //setLastSearchTerm(q);
    setNoOfSearch(noOfSearch + 1);

    if (!auth.user && noOfSearch > MAX_SEARCHES_ANONYMOUS) {
      setShowMore(false);
      setPricingModal(true);
    }
  };

  function hexToHsl(hex) {
    let r = parseInt(hex.slice(1, 3), 16) / 255,
      g = parseInt(hex.slice(3, 5), 16) / 255,
      b = parseInt(hex.slice(5, 7), 16) / 255,
      max = Math.max(r, g, b),
      min = Math.min(r, g, b),
      h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return [h, s, l];
  }
  function hexToRgb(hex) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  }
  useEffectOnce(() => {
    let mounted = true;
    if (mounted) {
      const currentSearchTerm = searchParams.get("q") || "";

      if (searchParams.get("q")) {
        setSearchTerm(searchParams.get("q"));
      }

      const pageData = () => {
        // console.log('calling runSearch from useEffect')

        if (currentSearchTerm) {
          runSearch2("", currentSearchTerm, 0);
        } else {
          setShowMore(false);
        }

        // console.log(
        //   "GetImagesBySearchTerm - pageData ",
        //   currentSearchTerm,
        //   " yielded ",
        //   data.length,
        //   " images"
        // );
      };

      const categoriesList = async () => {
        const response = await ListService.GetCategories();
        // console.log("fetching categories");
        setCategories(response);
      };

      const topicsList = async () => {
        const response = await ListService.GetAllTopics();
        // console.log("fetching topics");

        // remove any dupes
        let uniqueTopics = [...new Set(response)];

        setTopics(uniqueTopics);
      };

      const tagsList = async () => {
        const response = await ListService.GetTag();
        // console.log("fetching tags");
        setTags(response);
      };

      const materialsList = async () => {
        const response = await ListService.GetMaterials();
        // console.log("fetching materials");
        setMaterials(response);
      };

      const heightList = async () => {
        //const response = await ListService.GetResolution();
        // console.log("fetching materials");
        let newHeights = [768, 960, 1024, 1280, 1536]; //, 1792, 2048, 4096];

        /*
                response.forEach(width => {
                    if (!newHeight.includes(width.split("x")[1])) {
                        newHeight.push(width.split("x")[1])
                    }
                })
                */

        setHeights(newHeights);
      };
      const widthList = async () => {
        //const response = await ListService.GetResolution();
        let newWidths = [768, 960, 1024, 1280, 1536]; //, 1792, 2048, 4096];

        /*
                response.forEach(width => {
                    if (!newWidth.includes(width.split("x")[0])) {
                        newWidth.push(width.split("x")[0])
                    }
                })
                */
        setWidths(newWidths);
      };

      const relatedSearchesList = async () => {
        const response = await ListService.GetRelatedFromKeyword(
          currentSearchTerm
        );

        console.log("relatedSearches() - response: ", response);

        setRelatedSearches(response.slice(0, 5));
      };

      const trendingSearchesList = async () => {
        const response = await ListService.GetTrendingSearches2();

        console.log("trendingSearchesList2() - response: ", response);

        setTrendingSearches(response.slice(0, 5));
      };

      pageData(searchParams.get("q"));

      const colorsListInit = async () => {
        if (colors.length > 0) return;

        // filter colors by label by retrieving a random selection of colors for each label
        let blues = colorsList
          .filter((color) => color.name.includes("Blue"))
          .sort(() => 0.5 - Math.random())
          .slice(0, 10);
        let reds = colorsList
          .filter((color) => color.name.includes("Red"))
          .sort(() => 0.5 - Math.random())
          .slice(0, 10);
        let greens = colorsList
          .filter((color) => color.name.includes("Green"))
          .sort(() => 0.5 - Math.random())
          .slice(0, 10);
        let yellows = colorsList
          .filter((color) => color.name.includes("Yellow"))
          .sort(() => 0.5 - Math.random())
          .slice(0, 10);
        let oranges = colorsList
          .filter((color) => color.name.includes("Orange"))
          .sort(() => 0.5 - Math.random())
          .slice(0, 10);
        let purples = colorsList
          .filter((color) => color.name.includes("Purple"))
          .sort(() => 0.5 - Math.random())
          .slice(0, 10);
        let pinks = colorsList
          .filter((color) => color.name.includes("Pink"))
          .sort(() => 0.5 - Math.random())
          .slice(0, 10);
        let browns = colorsList
          .filter((color) => color.name.includes("Brown"))
          .sort(() => 0.5 - Math.random())
          .slice(0, 10);
        let grays = colorsList
          .filter((color) => color.name.includes("Gray"))
          .sort(() => 0.5 - Math.random())
          .slice(0, 10);
        let whites = colorsList
          .filter((color) => color.name.includes("White"))
          .sort(() => 0.5 - Math.random())
          .slice(0, 10);

        let colorsList2 = [
          ...blues.sort((a, b) => (a.hex > b.hex ? 1 : -1)),
          ...reds.sort((a, b) => (a.hex > b.hex ? 1 : -1)),
          ...greens.sort((a, b) => (a.hex > b.hex ? 1 : -1)),
          ...yellows.sort((a, b) => (a.hex > b.hex ? 1 : -1)),
          ...oranges.sort((a, b) => (a.hex > b.hex ? 1 : -1)),
          ...purples.sort((a, b) => (a.hex > b.hex ? 1 : -1)),
          ...pinks.sort((a, b) => (a.hex > b.hex ? 1 : -1)),
          ...browns.sort((a, b) => (a.hex > b.hex ? 1 : -1)),
          ...grays.sort((a, b) => (a.hex > b.hex ? 1 : -1)),
          ...whites.sort((a, b) => (a.hex > b.hex ? 1 : -1)),
        ];

        // sort by color similarity by using hex value
        //colorsList2.sort((a, b) => (hexToHsl(a.hex) > hexToHsl(b.hex)) ? 1 : -1)
        //colorsList2.sort((a, b) => (hexToRgb(a.hex) > hexToRgb(b.hex)) ? 1 : -1)
        colorsList2.sort((a, b) => (a.hex > b.hex ? 1 : -1));

        // place these colors on top of sorted list
        colorsList2.unshift({ hex: "#000000", name: "Black" });
        colorsList2.unshift({ hex: "#FFFFFF", name: "White" });
        colorsList2.unshift({ hex: "#808080", name: "Gray" });
        colorsList2.unshift({ hex: "#FF0000", name: "Red" });
        colorsList2.unshift({ hex: "#800000", name: "Maroon" });
        colorsList2.unshift({ hex: "#FFFF00", name: "Yellow" });
        colorsList2.unshift({ hex: "#808000", name: "Olive" });
        colorsList2.unshift({ hex: "#00FF00", name: "Lime" });
        colorsList2.unshift({ hex: "#008000", name: "Green" });
        colorsList2.unshift({ hex: "#00FFFF", name: "Aqua" });
        colorsList2.unshift({ hex: "#008080", name: "Teal" });
        colorsList2.unshift({ hex: "#0000FF", name: "Blue" });
        colorsList2.unshift({ hex: "#000080", name: "Navy" });
        colorsList2.unshift({ hex: "#FF00FF", name: "Fuchsia" });
        colorsList2.unshift({ hex: "#800080", name: "Purple" });
        colorsList2.unshift({ hex: "#FFA500", name: "Orange" });
        colorsList2.unshift({ hex: "#A52A2A", name: "Brown" });
        colorsList2.unshift({ hex: "#FFC0CB", name: "Pink" });

        setColors(colorsList2);

        console.log("colorsList2: ", colorsList2.length, " colors");
      };

      categoriesList();
      topicsList();
      colorsListInit();
      //tagsList();
      //materialsList();
      heightList();
      widthList();

      //relatedSearchesList();
      //getFeatured();
      //trendingSearchesList();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const colorsCombo = () => {
    <div className="col">
      <div className="px-4 text-center">
        <div className="d-flex align-items-center justify-content-center">
          <MultiDropdown
            className="w-100"
            list={colors}
            value={selectedColor}
            name="name"
          >
            Colors
          </MultiDropdown>
        </div>
      </div>
    </div>;
  };

  const categoriesCombo = () => {
    <div className="col">
      <div className="px-4 text-center">
        <div className="d-flex align-items-center justify-content-center">
          <MultiDropdown className="w-100" list={categories}>
            Category
          </MultiDropdown>
        </div>
      </div>
    </div>;
  };

  const getSearchList = () => {
    /*
        const combined = [
          ...categories,
          ...tags,
          ...topics,
          ...colors,
          ...materials,
        ];
        */
    const combined = [
      ...categories,
      ...topics,
      ...tags,
      ...colors,
      ...heights,
      ...widths,
    ];
    combined.map((e) => e.trim().charAt(0).toUpperCase() + e.trim().slice(1));
    return [...new Set(combined)].sort();
  };

  const getRandomTag = () => {
    // set query parameter to random tag
    const list = getSearchList();
    const index = Math.floor(Math.random() * list.length);
    return list[index];
  };

  const getResultsString = () => {
    let buffer = "";
    if (!data) {
      buffer = "No results found";
    } else if (loading) {
      buffer = `Loading results...`;
    } else if (data.length === 0) {
      buffer = "No results found";
    } else if (data.length === 1) {
      buffer = "1 result found";
    } else if (data.length % MAX_RESULTS === 0) {
      buffer = data.length + ` results found`;
      // buffer = "first " + data.length + " results found";
    } else {
      buffer = data.length + ` results found`;
    }

    return buffer;
  };

  const getResultsStringContext = () => {
    console.log(
      "getResultsStringContext() - selectedCategory: ",
      selectedCategory,
      " selectedTopic: ",
      selectedTopic,
      " selectedTag: ",
      selectedTag,
      " selectedColor: ",
      selectedColor,
      " selectedHeight: ",
      selectedHeight,
      " selectedWidth: ",
      selectedWidth,
      " searchTerm: ",
      searchTerm
    );
    let buffer = "";
    if (searchTerm === "") {
      buffer = "Choose a category or topic to search";
    } else {
      if (selectedCategory) {
        buffer += " Category(" + selectedCategory + ")";
      }
      if (selectedTopic) {
        buffer += " Topic(" + selectedTopic + ")";
      }
      if (selectedTag) {
        buffer += " Tag(" + selectedTag + ")";
      }
      if (selectedColor) {
        buffer += " Color(" + selectedColor + ")";
      }
      if (selectedHeight) {
        buffer += " Height(" + selectedHeight + ")";
      }
      if (selectedWidth) {
        buffer += " Width(" + selectedWidth + ")";
      }
      if (
        !selectedCategory &&
        !selectedTopic &&
        !selectedColor &&
        !selectedColor &&
        !selectedHeight &&
        !selectedWidth &&
        searchTerm !== ""
      ) {
        buffer += " Search(" + filterProfanity(searchTerm) + ")";
      }
    }

    return buffer;
  };

  const getSearchResult = () => {
    return (
      <div className="search__result" id="search__result">
        <Typography
          sx={{
            margin: 1,
            fontFamily: "Raleway",
            fontSize: 22,
            fontWeight: "bold",
            marginTop: "40px",
          }}
        >
          {getResultsString()}
        </Typography>
        <Typography
          sx={{
            margin: 1,
            fontFamily: "Raleway",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          {getResultsStringContext()}
        </Typography>
        <Typography
          sx={{
            margin: 1,
            fontFamily: "Raleway",
            fontSize: 12,
            fontStyle: "italic",
          }}
        >
          Click thumbnail to view image
        </Typography>
      </div>
    );
  };

  const getCategoryFilter = () => {
    return (
      <FormControl sx={{ m: 1, width: 150 }}>
        <InputLabel id="categories_labels">Category</InputLabel>
        <Select
          labelId="categories_labels"
          id="categories"
          value={categories.length > 0 ? selectedCategory : ""}
          data={categories}
          label="Categories"
          renderValue={(value) => value}
          onChange={(e) => handleCategoryChange(e)}
          sx={{ borderRadius: "15px" }}
        >
          {categories.map((category) => (
            <MenuItem
              key={category}
              value={category}
              selected={category === selectedCategory}
            >
              {selectedCategory === category ? (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              ) : null}
              <ListItemText inset={selectedCategory !== category}>
                {category}
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const getTopicFilter = () => {
    console.log("topics: ", topics);
    return (
      <FormControl sx={{ m: 1, width: 150 }}>
        <InputLabel id="topics_labels">Topic</InputLabel>
        <Select
          labelId="topics_labels"
          id="topics"
          value={topics.length > 0 ? selectedTopic : ""}
          data={topics}
          label="Topics"
          renderValue={(value) => value}
          onChange={(e) => handleTopicChange(e)}
          sx={{ borderRadius: "15px" }}
        >
          {topics.map((topic) => (
            <MenuItem
              key={topic}
              value={topic}
              selected={topic === selectedTopic}
            >
              {selectedTopic === topic ? (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              ) : null}
              <ListItemText inset={selectedTopic !== topic}>
                {topic}
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  /*
    const getTagsFilter = () => {
        return (
            <FormControl sx={{ m: 1, width: 150 }}>
                <InputLabel id="tags_labels">Tags</InputLabel>
                <Select
                    labelId="tags_labels"
                    id="tags"
                    value={tags.length > 0 ? selectedTag : ''}
                    data={tags}
                    label="Tags"
                    renderValue={(value) => value}
                    onChange={(e) => handleTagChange(e)}
                    sx={{ borderRadius: "15px" }}
                >
                    {tags.map((tags) => (
                        <MenuItem
                            key={tags}
                            value={tags}
                            selected={tags === selectedTag}
                        >
                            {selectedTag === tags ? (
                                <ListItemIcon>
                                    <Check />
                                </ListItemIcon>
                            ) : null}
                            <ListItemText inset={selectedTag !== tags}>
                                {tags}
                            </ListItemText>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }
*/

  const getColorFilter = () => {
    return (
      <FormControl sx={{ m: 1, width: 150 }}>
        <InputLabel id="colors_labels">Colors</InputLabel>
        <Select
          labelId="colors_labels"
          id="colors"
          value={colors.length > 0 ? selectedColor : ""}
          data={colors}
          label="Colors "
          renderValue={(value) => value}
          onChange={(e) => handleColorChange(e)}
          sx={{ borderRadius: "15px" }}
        >
          {colors.map((color) => (
            <MenuItem
              key={color["hex"]}
              value={color}
              selected={color === selectedColor}
            >
              {selectedColor === color ? (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              ) : null}
              <Button
                inset={selectedColor !== color}
                style={{ backgroundColor: color["hex"], color: color["hex"] }}
              >
                {color["hex"]}
              </Button>
              <ListItemText inset={selectedColor !== color}>
                {color["hex"]}
              </ListItemText>
              <ListItemText inset={selectedColor !== color}>
                {color["name"]}
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const getHeightFilter = () => {
    return (
      <FormControl sx={{ m: 1, width: 150 }}>
        <InputLabel id="height_labels">Height</InputLabel>
        <Select
          labelId="height_labels"
          id="height"
          value={heights.length > 0 ? selectedHeight : ""}
          data={heights}
          label="Height"
          renderValue={(value) => value}
          onChange={(e) => handleHeightChange(e)}
          sx={{ borderRadius: "15px" }}
        >
          {heights.map((height) => (
            <MenuItem
              key={height}
              value={height}
              selected={height === selectedHeight}
            >
              {selectedHeight === height ? (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              ) : null}
              <ListItemText inset={selectedHeight !== height}>
                {height}
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const getWidthFilter = () => {
    return (
      <FormControl sx={{ m: 1, width: 150 }}>
        <InputLabel id="width_labels">Width</InputLabel>
        <Select
          labelId="width_labels"
          id="width"
          value={widths.length > 0 ? selectedWidth : ""}
          data={widths}
          label="Width"
          renderValue={(value) => value}
          onChange={(e) => handleWidthChange(e)}
          sx={{ borderRadius: "15px" }}
        >
          {widths.map((width) => (
            <MenuItem
              key={width}
              value={width}
              selected={width === selectedWidth}
            >
              {selectedWidth === width ? (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              ) : null}
              <ListItemText inset={selectedWidth !== width}>
                {width}
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const checkAuth = () => {
    return !auth.user ? (
      <div className="bg-banner px-4 text-center ">
        <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
          <p className="mb-0 me-3 text-white">
            Would you like to see a larger number of search results?
          </p>
          <div className="button" onClick={() => navigate("/signup")}>
            Join For Free
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const loadImages = () => {
    return (
      <div>
        {loading && !loadingMore ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "10vh",
            }}
          >
            <Backdrop open={loading && !loadingMore}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "4px",
            }}
          >
            <Tiles 
              props={props} 
              data={data}
              handleQuickDownload={handleQuickDownload}              
            >

            </Tiles>
            {loadingMore ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10vh",
                }}
              >
                <Backdrop open={loadingMore}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        )}
        {showMore && !loading ? (
          <div className="px-4 my-5 text-center">
            <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
              <h5 className="fw-bold color-primary">
                <Button
                  className="rounded-pill"
                  variant="contained"
                  disabled={page >= MAX_NUM_PAGED - 1}
                  onClick={() => {
                    loadMore();
                  }}
                >
                  {page >= MAX_NUM_PAGED - 1
                    ? "Maximum search results - please refine search"
                    : "Load More Images - PAGE " + (page + 1) + " (" + MAX_NUM_PAGED + " max)"}
                </Button>
              </h5>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const showFeatured = () => {
    return (
      featured &&
      featured.length > 0 && (
        <Box
          className="px-4 text-center color-primary"
          title="Featured Images"
          label="Featured Images"
          sx={{
            width: "80%",
            minWidth: "70%",
            maxHeight: "280px",
            justifyContent: "center",
            textAlign: "center",
            alignContent: "center",
            borderRadius: "10px",

            backgroundColor: "#EAEAEA",
            marginLeft: 3,
            marginRight: 3,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Raleway",
              fontSize: 22,
              fontWeight: "bold",
            }}
          >
            Featured Images
          </Typography>

          <Tiles
            props={props2}
            data={featured.slice(0, 5)}
            faves={null}
            loadMore={loadMore}
          ></Tiles>
        </Box>
      )
    );
  };

  const showPricingModal = () => {
    return (
      <Modal
        setOpenModal={setPricingModal}
        title=""
        disableEscapeKeyDown={true}
        onClose={() => pricingModalClose()}
      >
        <Box sx={style}>
          <div>
            <p>
              You have reached your search limit as an anonymous user, please
              sign up for a free account to continue enjoying our services.
            </p>
          </div>
          <div className="my-3 px-4 text-center ">
            <div className=" mx-auto py-2">
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/credits");
                }}
              >
                Join For Free
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      runBarSearch();
    }
  }

  const runBarSearch = () => {
    // reset combo boxes
    setSelectedCategory("");
    setSelectedTopic("");
    //setSelectedTag("");
    setSelectedColor("");
    setSelectedHeight("");
    setSelectedWidth("");
    runSearch2("", searchBarTerm, 0);

    /*
    // scroll down to results area
    const element = document.getElementById("search__result");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    */
  };

  const searchBar = () => {
    return (
      <div>
        <div
          className="searchBar col-lg-6 mx-auto"
          style={{
            display: "flex",
            width: "80%",
            minWidth: "400px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            marginTop: "50px",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          }}
        >
          <input
            id="searchQueryInput"
            type="text"
            name="searchQueryInput"
            placeholder="search for AI-generated stock images"
            value={searchBarTerm}
            onChange={(e) => {
              setSearchBarTerm(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
          <button
            id="searchQuerySubmit"
            type="submit"
            name="searchQuerySubmit"
            onClick={runBarSearch}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
          <br />
        </div>
        <div>
          <Typography
            sx={{
              margin: 1,
              fontFamily: "Raleway",
              fontSize: 12,
              fontStyle: "italic",
              fontWeight: "400",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            Use quotes for exact match, for example: "car", will return images
            that contain the actual word 'car',
            <br />
            no quotes will return images that contain the letters '-car-' in the
            title, description, topics and/or tags, such as 'carrot', 'carpet',
            'scarf', 'scar', etc.
          </Typography>
        </div>
      </div>
    );
  };

  const getSearchFilters = () => {
    return (
      <div
        className="filter"
        style={{
          display: "flex",
          color: "#000000",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#f5f5f5",
          borderRadius: "10px",
          marginTop: "80px",
          marginBottom: "10px",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          border: "1px solid #ddd",
          width: "80%",
        }}
      >
        {!auth.user && (
          <Typography
            sx={{
              margin: 1,
              fontFamily: "Raleway",
              fontSize: 12,
              fontWeight: "400",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            Sign up for a free account to access advanced search features
          </Typography>
        )}

        {auth.user && (
          <div className="filter__section">
            <Typography
              sx={{
                margin: 1,
                fontFamily: "Raleway",
                fontSize: 18,
                fontWeight: "600",
              }}
            >
              Advanced Search:
            </Typography>
            {getCategoryFilter()}
            {getTopicFilter()}
            {
              //getTagsFilter()
            }
            {getColorFilter()}
            {getHeightFilter()}
            {getWidthFilter()}
            {
              // reset filters
              <Button
                label="Reset Filters"
                title="Reset Filters"
                style={{
                  // make button look like cool chip using primary color
                  backgroundColor: "#e0e0e0",
                  borderRadius: "20px",
                  color: "#000000",
                  padding: "5px 10px",
                  margin: "5px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "none",
                }}
                onClick={() => {
                  setSelectedCategory("");
                  setSelectedTopic("");
                  //setSelectedTag("");
                  setSelectedColor("");
                  setSelectedHeight("");
                  setSelectedWidth("");
                  //runSearch2("", "", 0);
                }}
              >
                <Typography
                  // center content and apply padding
                  sx={{
                    color: "var(--primary-color)",
                    margin: 1,
                    padding: "2px",
                    fontFamily: "Raleway",
                    fontSize: 14,
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  <i
                    style={{
                      marginRight: "15px",
                      fontSize: "16px",
                      color: "var(--secondary-color)",
                    }}
                    className="fa-solid fa-redo"
                  ></i>
                  Reset Filters
                </Typography>
              </Button>
            }
            <Typography
              sx={{
                margin: 1,
                fontFamily: "Raleway",
                fontSize: 12,
                fontStyle: "italic",
                fontWeight: "400",
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              Use 'Advanced Search' to perform categorical searches, choose a
              single filter to perform a search or combine 'Category' and one
              additional filter to narrow down your search
            </Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "73vh",
        minWidth: "400px",
        //maxWidth: "1920px",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <Box
        className="bg-search-hero"
        sx={{
          minWidth: "90%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          contain: "content",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        {searchBar()}
        {getSearchFilters()}
      </Box>

      {
        //showFeatured()
      }
      {/*
      <Box
        sx={{
          minWidth: "90%",
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        { //renderTrendingSearches()
        }
      </Box>
      
      <Box
        sx={{
          minWidth: "90%",
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        {
          //getRelatedSearches()
        }
      </Box>
      */}

      {getSearchResult()}

      <div className="container mb-5">
        <div className="row row-cols-2 row-cols-md-3 row-cols-xl-5 gy-3">
          {categoriesCombo}
          {colorsCombo}
        </div>
      </div>

      {checkAuth()}
      {loadImages()}

      {pricingModal && showPricingModal()}
    </Box>
  );
}
