import React, { useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import {
  Box,
  Container,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  CardHeader,
  Button,
  Paper,
  Input,
  TextField,
  CardMedia,
  Chip,
} from "@mui/material";

import {
  Check,
  CheckCircleOutline,
  DownloadOutlined,
  PaletteOutlined,
  EditOutlined,
  ShareOutlined,
  Download,
  Details,
  ImageRounded,
  ImageOutlined,
  FavoriteOutlined,
  Visibility
} from "@mui/icons-material";

import { DetermineImageCreditPrice } from "../../utils/utilsProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
/*
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faPalette } from "@fortawesome/free-solid-svg-icons";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { faHandPointer } from "@fortawesome/free-regular-svg-icons";  

import { faArrowPointer } from "@fortawesome/free-solid-svg-icons";
import loveIcon from "../../assets/img/loveIcon.svg";
import notFavoritedIcon from "../../assets/img/notFavoritedIcon.png";
import SocialShare from "../social-share/SocialShare";
import ImageService from "../../services/image.service";
*/
import "./Tiles2.scss";

import { useAuth } from "../../services/auth/use-auth";
import { isMobile } from "react-device-detect";
import { Stack } from "react-bootstrap";

export default function Tiles({ data, props, faves, isUploads, handleQuickDownload }) {
  const onTablet = useMediaQuery("(max-width:900px)");
  const onMobile = useMediaQuery("(max-width:600px)");
  const onXSMMobile = useMediaQuery("(max-width:331px)");
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);


  const auth = useAuth();

  // loop through favorites and set is_favorited to true
  if (faves && faves.length > 0) {
    if (data && data.length > 0)
      data.map((item, index) => {
        faves.map((fave, index) => {
          if (item.image_id === fave.image_id) {
            item.is_favorited = true;
          }
        });
      });
  }

  const download = async (url) => {
    // console.log("downloadImage: " + JSON.stringify(data));
    var urlDownload = url ? url : data.public_url;
    const blob = await fetch(urlDownload).then((r) => r.blob());
    const urlBlob = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = urlBlob;
    link.target = "_blank";
    link.download =
      "SmartAI-StockImages_" +
      urlDownload.substr(urlDownload.lastIndexOf("/") + 1);
    link.click();
  };

  /*
  const handleDownload = (image) => {
    download(image.public_url);
  };
*/

  const handleViewDetail = (image) => {
    navigate(`/image?id=${image.image_id}`);
  };

  const renderHoverActions3 = (item) => {
    return (
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          background: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(8px)',
          transform: 'translateY(100%)',
          transition: 'transform 0.3s ease-in-out',
          padding: '20px',
          '&:hover': {
            transform: 'translateY(0)',
          }
        }}
      >
        {/* Top Section - Actions */}
        <Box sx={{ 
          display: 'flex', 
          gap: 2,
          justifyContent: 'flex-end'
        }}>
          <IconButton
            sx={{
              bgcolor: 'rgba(255,255,255,0.15)',
              backdropFilter: 'blur(4px)',
              '&:hover': {
                bgcolor: 'rgba(255,255,255,0.25)',
              }
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleViewDetail(item);
            }}
          >
            <Visibility sx={{ color: 'white' }} />
          </IconButton>
          <IconButton
            sx={{
              bgcolor: 'rgba(255,255,255,0.15)',
              backdropFilter: 'blur(4px)',
              '&:hover': {
                bgcolor: 'rgba(255,255,255,0.25)',
              }
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleQuickDownload(item);              
            }}
          >
            <Download sx={{ color: 'white' }} />
          </IconButton>
        </Box>
          {/* Bottom Section - Title & Metadata */}
          <Box sx={{ 
          display: 'grid', 
          gap: 1,
        }}>
          <Typography 
            variant="h6" 
            sx={{ 
              color: 'white',
              textShadow: '0 2px 4px rgba(0,0,0,0.2)',
              fontWeight: 600,
              fontSize: '1.1rem',
              lineHeight: 1.2
            }}
          >
            {item.title}
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            gap: 2,
            color: 'rgba(255,255,255,0.8)'
          }}>
            <Typography variant="caption">
              {item.width} × {item.height}
            </Typography>
            <Typography variant="caption">
              {(item.size / 1024 / 1024).toFixed(1)} MB
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  const renderHoverActions2 = (item) => {
    return (
      <div
        style={{
         // create a grid for a slick style to show overlay on hover
         // have the action buttons on the top and the title at the bottom
          display: "grid",
          gridTemplateRows: "auto 3fr",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: "1",
          opacity: "0.8",
          transition: "0.5s ease",
        }}
      >
        <ImageListItemBar
          className="row show-on-hover-no-click"
          sx={{
            padding: "4px",
            background:
              "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
              "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
            height: "100%",                   
          }}
          title={
          <Box
            style={{
              color: "white",
              width: "100%",
              textAlign: "center",
            }}
          >
        
            <Typography
                variant="h6"
                color="text.secondary"
                style={{
                  bottom: "0",
                  padding: "4px",
                  color: "white",
                  backgroundColor: "transparent",
                  width: "100%",
                  textAlign: "center",

                }}
              >                            
              {item.category}
              </Typography>
            </Box>
          }
          actionIcon={
            <Box
              sx={{
                display: "flow",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",     
                width: "100%",            
              }}
            >
               <IconButton
                sx={{ color: "rgba(255, 255, 255, 0.85)" }}
                aria-label={`info about ${item.title}`}
                onClick={() => handleQuickDownload(item.image_id)}
              >
                <FontAwesomeIcon icon={solid("download")} 
                  style={{
                    color: "white",
                    backgroundColor: "transparent",
                    width: "36px",
                    height: "36px"
                  }}
                />
              </IconButton>
              <IconButton
                sx={{ color: "rgba(255, 255, 255, 0.85)" }}
                aria-label={`info about ${item.title}`}
                onClick={() => navigate("/image?id=" + item.image_id)}
              >
                <ImageRounded
                  style={{
                    color: "white",
                    backgroundColor: "transparent",
                    width: "40px",
                    height: "40px"
                  }}
                
                />
              </IconButton>

               { /*
              <IconButton
                sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                aria-label={`info about ${item.title}`}
                onClick={() => navigate("/studio?id=" + item.image_id)}
              >
                <PaletteOutlined />
              </IconButton>
            */ }
              {/*
                    <SocialShare
                      color="#fff"
                      opacity={0.54}
                      url={window.location.href + 'image?id=' + item.image_id}
                      title={item.title}
                      decription={item.description}
                      hashtags={[item.category]}
                    />
                    */}

        <Typography
            variant="body2"
            color="text.secondary"
            style={{
              bottom: "0",
              padding: "4px",
              color: "white",
              width: "100%",
              textAlign: "center",
            }}
            >
               {item.title.length > 24 
                ? isMobile ? item.title.substring(0, 24) + "..." : item.title.substring(0, 40) + "..."
                : item.title
              }
            </Typography>   
            <Typography
            variant="body2"
            color="text.secondary"
            style={{
              bottom: "0",
              padding: "2px",
              color: "white",
              width: "100%",
              textAlign: "center",
            }}
            >
              {item.width} x {item.height}
            </Typography>   
            <Typography
            variant="body2"
            color="text.secondary"
            style={{
              bottom: "0",
              padding: "2px",
              color: "white",
              width: "100%",
              textAlign: "center",
            }}
            >
              {DetermineImageCreditPrice(item.width, item.height, item.model)} credits
            </Typography>   
            </Box>
          }
          label={item.title}          
          //title={item.title} }
        />      
        
      </div>
      
    );
  };

  const renderHoverActions = (item) => {
    return (
      <div className="overlay fs-5">
        <div className="row">
          <div className="col">
            <div className="text-start text-wrap">{item.category + "..."}</div>
          </div>
          <div className="col text-end">
            <i
              onClick={() => download(item.public_url)}
              className="bottom fa-sharp fa-solid fa-download pe-1"
            ></i>
          </div>
        </div>
      </div>
    );
  };

  return (
    // dump data to console, only if is_favorited=  true
    //faves.map((item, index) => (console.log("TILES:FAVE: ", index, " ", item))),

    <Paper
      className="container"
      maxWidth="lg"
      sx={{
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        borderRadius: 2,
        margin: 2,
        padding: 2,
        border: "none",
        boxShadow: "none",
        backgroundColor: "transparent",
        "&:hover": {
          boxShadow: "none",
        },
      }}
    >
    
      <ImageList
        variant="masonry"
        cols={
          onMobile
            ? onXSMMobile
              ? 1
              : 1
            : onTablet
            ? props?.noPerRow - 2
            : props?.noPerRow
        }
        gap={props ? props.gap : 4}
        sx={{
          borderRadius: 2,
          border: "none",
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        {data.map((item, index) => (
          <ImageListItem
            key={index}
            sx={{
              borderRadius: 2,
              padding: "0px",
              border: "none",
              boxShadow: "none",
              backgroundColor: "transparent",
              "&:hover": {
                boxShadow: "2px 2px 6px 3px opacity(0.3) #183E4B",
                "& .MuiCardHeader-title": {
                  display: "block",
                  height: "auto",
                },
                "& .MuiCardHeader-action": {
                  display: "block",
                },

                transform: "scale(1.05)",
                transition: "0.2s",
              },
            }}
          >
            <Card
              sx={{
                backgroundColor: "white",
                width: "100%",
                height: "auto",
                padding: 0.5,

                borderRadius: 2,
                border: "none",
                overflow: "hidden",
                boxShadow: "1px 1px 3px 1px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => {
                //isUploads ? navigate(`/studio?id=${item.image_id}`) : navigate(`/image?id=${item.image_id}`);
              }}
            >
              {
                // if props.showQuickActions is true, show the quick actions
                props.showQuickActions && (
                  <CardHeader
                    sx={{
                      backgroundColor: "#183E4B",
                      color: "white",
                      padding: 0,
                      margin: 0,
                      borderRadius: 2,
                      border: "none",
                      "& .MuiCardHeader-title": {
                        display: "none",
                      },
                      "& .MuiCardHeader-action": {
                        display: "none",
                      },
                    }}
                    title={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: 0,
                          margin: 0,
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {renderHoverActions2(item)
                        }
                      </Box>
                    }
                  />
                )
              }
              <CardContent
                sx={{
                  backgroundColor: "transparent",
                  border: "none",
                  padding: 0,
                  "&:last-child": {
                    paddingBottom: 0,
                  },
                  "& .MuiCardContent-root": {
                    padding: 0,
                  },
                  width: "100%",
                }}
              >
                {
                  <img
                    src={item.public_url_thumbnail}
                    alt={item.title}
                    loading="lazy"
                    style={{
                      backgroundColor: "transparent",
                      width: "100%",
                      maxWidth: "100%",
                      height: "auto",
                      borderRadius: 4,
                      border: "none",
                      boxShadow: "none",
                    }}
                  />
                }
              </CardContent>
            </Card>
          </ImageListItem>
        ))}
      </ImageList>

    </Paper>
  );
}
