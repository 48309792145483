export function DetermineTextCreditPrice(numTokens, model) {

    let price = 0;
    switch (model) {
        case "low":
            
                if (numTokens <= 64) {
                    price = 1;
                } else if (numTokens <= 128) {
                    price = 2;
                } else if (numTokens <= 256) {
                    price = 3;
                } else if (numTokens <= 384) {
                    price = 3;
                } else if (numTokens <= 512) {
                    price = 4;
                } else if (numTokens <= 768) {
                    price = 5;
                } else if (numTokens <= 1024) {
                    price = 5;
                } else if (numTokens <= 1536) {
                    price = 5;
                } else if (numTokens <= 2048) {
                    price = 5;
                } else if (numTokens <= 4096) {
                    price = 5;
                } else {
                    price = 5;
                }
                break;
        case "medium":
                if (numTokens <= 64) {
                    price = 1;
                } else if (numTokens <= 128) {
                    price = 2;
                } else if (numTokens <= 256) {
                    price = 3;
                } else if (numTokens <= 384) {
                    price = 3;
                } else if (numTokens <= 512) {
                    price = 4;
                } else if (numTokens <= 768) {
                    price = 5;
                } else if (numTokens <= 1024) {
                    price = 5;
                } else if (numTokens <= 1536) {
                    price = 5;
                } else if (numTokens <= 2048) {
                    price = 5;
                } else if (numTokens <= 4096) {
                    price = 5;
                } else {
                    price = 5;
                }
            break;
        case "high":
                if (numTokens <= 64) {
                    price = 1;
                } else if (numTokens <= 128) {
                    price = 2;
                } else if (numTokens <= 256) {
                    price = 3;
                } else if (numTokens <= 384) {
                    price = 4;
                } else if (numTokens <= 512) {
                    price = 5;
                } else if (numTokens <= 768) {
                    price = 6;
                } else if (numTokens <= 1024) {
                    price = 7;
                } else if (numTokens <= 1536) {
                    price = 8;
                } else if (numTokens <= 2048) {
                    price = 9;
                } else if (numTokens <= 4096) {
                    price = 10;
                } else {
                    price = 5;
                }
            
            break;
        default:
            price = 3;
            break;
    }
    return price;
}

export function DetermineImageCreditPrice(width, height, model) {
    let price = 0;
    let totalPixels = width * height;

    let p_512x512 = 262144;
    let p_640x480 = 307200;
    let p_800x600 = 480000;
    let p_1024x768 = 786432;
    let p_1024x1024 = 1048576;
    let p_1280x960 = 1228800;
    let p_1600x1200 = 1920000;
    let p_2048x1536 = 3145728;
    let p_2560x1920 = 4915200;
 

    switch (model) {
        case "low":
            if (totalPixels <= p_512x512) {
                price = 1;
            } else if (totalPixels <= p_640x480) {
                price = 1;
            } else if (totalPixels <= p_800x600) {
                price = 2;
            } else if (totalPixels <= p_1024x768) {
                price = 2;               
            } else if (totalPixels <= p_1024x1024) {
                price = 3;               
            } else if (totalPixels <= p_1280x960) {
                price = 4;
            } else if (totalPixels <= p_1600x1200) {
                price = 4;
            } else if (totalPixels <= p_2048x1536) {
                price = 5;
            } else if (totalPixels <= p_2560x1920) {
                price = 6;
            } else {
                price = 7;
            }
            break;
        case "medium":
            if (totalPixels <= p_512x512) {
                price = 1;
            } else if (totalPixels <= p_640x480) {
                price = 1;
            } else if (totalPixels <= p_800x600) {
                price = 2;
            } else if (totalPixels <= p_1024x768) {
                price = 3;
            } else if (totalPixels <= p_1024x1024) {
                price = 4;     
            } else if (totalPixels <= p_1280x960) {
                price = 5;
            } else if (totalPixels <= p_1600x1200) {
                price = 6;
            } else if (totalPixels <= p_2048x1536) {
                price = 7;
            } else if (totalPixels <= p_2560x1920) {
                price = 8;
            } else {
                price = 9;
            }
            break;
        case "high":
            if (totalPixels <= p_512x512) {
                price = 1;
            } else if (totalPixels <= p_640x480) {
                price = 3;
            } else if (totalPixels <= p_800x600) {
                price = 3;
            } else if (totalPixels <= p_1024x768) {
                price = 5;
            } else if (totalPixels <= p_1024x1024) {
                price = 5;     
            } else if (totalPixels <= p_1280x960) {
                price = 7;
            } else if (totalPixels <= p_1600x1200) {
                price = 8;
            } else if (totalPixels <= p_2048x1536) {
                price = 9;
            } else if (totalPixels <= p_2560x1920) {
                price = 10;
            } else {
                price = 9;
            }
            break;
        default:
            price = 3;
            break;
    }
    return price;
}
            