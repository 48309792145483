import { apiURI } from "../shared/api.settings";

class ProductService {

  static GetProductList = async (userId, token) => {
      return fetch(`${apiURI}/product/list?userId=${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
          .then((response) => response.json());
  }
  
  static GetProductListByCategoryId = async (userId, categoryId, token) => {
    return fetch(`${apiURI}/product/list/category/${categoryId}?userId=${userId}`, {
      headers: {
          Authorization: `Bearer ${token}`,
      }
  })
        .then((response) => response.json());
  }
  static GetProductCreditMaps = async (userId, capabilityId, token) => {
    return fetch(`${apiURI}/product/credits/maps/${capabilityId}?userId=${userId}`, {
      headers: {
          Authorization: `Bearer ${token}`,
      }
  })
        .then((response) => response.json());
  }
  static GetProductPurchases = async (userId, token) => {
    return fetch(`${apiURI}/product/list/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => response.json());
  } 
  
  static GetCreditPacks = async (userId, id, token) => {
    return fetch(`${apiURI}/credits/packs?userId=${userId}`, {
      headers: {
          Authorization: `Bearer ${token}`,
      }
  })
        .then((response) => response.json());
  } 
  static GetSubscriptionTiers = async (userId, token) => {
    return fetch(`${apiURI}/subscription/tiers?userId=${userId}`, {
      headers: {
          Authorization: `Bearer ${token}`,
      }
  })
        .then((response) => response.json());
  } 
  static GetSubscriptionCapabilties = async (userId, subscriptionTierId, token) => {
    return fetch(`${apiURI}/product/subscription/capabilities/${subscriptionTierId}?userId=${userId}`, {
      headers: {
          Authorization: `Bearer ${token}`,
      }
  })
        .then((response) => response.json());
  } 
  /*
  static GetProductDetails = async (id) => {
    return fetch(`${apiURI}/product/details?productId=${id}`)
        .then((response) => response.json());
  }
    */

   static GenerateTextForCredits = async (userId, token, imageId, productId, numTokens, postType, themes, includeTags) => {
    // console.log("ProductService.GenerateTextForCredits: ", userId);
    // console.log("ProductService.GenerateTextForCredits: ", productId);
    // console.log("ProductService.GenerateTextForCredits: ", imageId);
  
    var body = 
      {
        "userId": userId,
        "productType": 4, //text generation
        "mode": 1, // "pay with credits"
        "assetId": imageId,
        "productId": `${productId}`,
        "numTokens": numTokens,
        "textType": postType,
        "Tags": themes,
        "IncludeTags": includeTags
      };
  
    // console.log("ProductService - PurchaseImageUsingCredits - body: ", body);
    return fetch(`${apiURI}/product/text/gen/credits`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
         Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }
  static InvokeAgenticActivityForCredits = async (userId, token, imageId, productId, numTokens, postType, options, includeTags) => {
    // console.log("ProductService.GenerateTextForCredits: ", userId);
    // console.log("ProductService.GenerateTextForCredits: ", productId);
    // console.log("ProductService.GenerateTextForCredits: ", imageId);
  
    var body = 
      {
        "userId": userId,
        "productType": 51, // specialized agents
        "mode": 1, // "pay with credits"
        "assetId": imageId,
        "productId": `${productId}`,
        "numTokens": numTokens,
        "textType": postType,
        "tags": options,
        "IncludeTags": includeTags
      };
  
    // console.log("ProductService - PurchaseImageUsingCredits - body: ", body);
    return fetch(`${apiURI}/product/agents/gen/credits`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
         Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }


  
  static SubscribeEarlyRelease = async (name, email, acceptTerms) => {
    // POST request using fetch with set headers
    const requestOptions = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
        body: JSON.stringify({ 
            user: { 
                id:'0', 
                name:name, 
                userId: 'NA', 
                emailAddress: email, 
                userType:''
            }, 
            mode: 1, 
            Releases: ['stock-image-alpha'] }) 
    };
    fetch(`${apiURI}/subscription/prospect/add`, requestOptions)
        .then(response => response.json());
}
static CapturePayment = async (userId, token, email,product,creditcard,billing, price) => {
  // console.log("ProductService.CapturePayment: ", userId);
  // console.log("ProductService.CapturePayment: ", JSON.stringify(product));
  // console.log("ProductService.CapturePayment: ", JSON.stringify(creditcard));
  // console.log("ProductService.CapturePayment: ", JSON.stringify(billing));
  // console.log("ProductService.CapturePayment: ", price);
  // console.log("ProductService.PurchaseImage: ", height);

  var body = 
    {
      "user": {
        "id": userId,
      },
      "paymentMode": 4,
      "paymentProfile": {
        "creditCard": {
          "creditCardNumber": creditcard.creditCardNumber,
          "expirationDateString": creditcard.expirationDateString,
          "cvv": creditcard.cvv,      
          "countryRegion": billing.CountryRegion,
          "state": billing.State,
          "city": billing.City,
          "addressLine1": billing.AddressLine1,
          "addressLine2": billing.AddressLine2,
          "postalCode": billing.PostalCode,
          "phoneNumber": billing.PhoneNumber,
          "email": email,
          "firstName": billing.FirstName,
          "lastName": billing.LastName
        }
      }
    };

  // console.log("ProductService - CapturePayment - body: ", body);
  return fetch(`${apiURI}/payment/chargecc`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
}





  static VaryImageUsingCredits = async (userId, token, productId, imageId, model, seed, imageUrlToVary, varyStrength, tags, includeTags) => {
     //console.log("VaryImageUsingCredits(userId): ", userId);
     //console.log("VaryImageUsingCredits(productId): ", productId);
     //console.log("VaryImageUsingCredits(imageId): ", imageId);
     //console.log("VaryImageUsingCredits(seed): ", seed);
     //console.log("VaryImageUsingCredits(tags): ", tags);
     //console.log("VaryImageUsingCredits(includeTags): ", includeTags);

    var body = 
      {
        "userId": `${userId}`,
        "productType": 6, // vary
        "mode": 1, // pay with credits
        "assetId": `${imageId}`,
        "seed": `${seed}`,
        "productId": `${productId}`,
        "tagsBuffer": `${tags}`,
        "varyImageUrl": `${imageUrlToVary}`, 
        "includeTags": includeTags,
        "model": `${model}`,
        "strength": varyStrength
      };

      //console.log("ProductService - VaryImageUsingCredits - body: ", body);
      //console.log("ProductService - VaryImageUsingCredits - body: ", JSON.stringify(body));

    return fetch(`${apiURI}/product/image/vary/credits`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }

  /*
  static VaryImageUsingCredits2 = async (userId, token, productId,imageId, model, seed, imageUrlToVary, tags, includeTags) => {
    console.log("ProductService.VaryImageUsingCredits2: ", userId);
    console.log("ProductService.VaryImageUsingCredits2: ", productId);
    console.log("ProductService.VaryImageUsingCredits2: ", imageId);

   var body = 
     {
       "userId": `${userId}`,
       "productType": 6, // vary
       "mode": 1, // pay with credits
       "assetId": `${imageId}`,
       "productId": `${productId}`,
       "tagsBuffer": `${tags}`,
       "varyImageUrl": `${imageUrlToVary}`, 
     };

     //console.log("ProductService - VaryImageUsingCredits - body: ", body);
     //console.log("ProductService - VaryImageUsingCredits - body: ", JSON.stringify(body));

   // console.log("ProductService - VaryImageUsingCredits - body: ", body);
   return fetch(`${apiURI}/product/image/vary/credits`, {
     method: "POST", // *GET, POST, PUT, DELETE, etc.
     mode: "cors", // no-cors, *cors, same-origin
     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
     headers: {
       "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
     },
     body: JSON.stringify(body),

   }).then((response) => response.json());
 }
   */

  static PurchaseImageUsingCredits = async (userId, token, productId,imageId) => {
    // console.log("ProductService.PurchaseImageUsingCredits: ", userId);
    // console.log("ProductService.PurchaseImageUsingCredits: ", productId);
    // console.log("ProductService.PurchaseImageUsingCredits: ", imageId);

    var body = 
      {
        "userId": userId,
        "productType": 0,
        "mode": 1,
        "assetId": imageId,
        "productId": `${productId}`
      };

    // console.log("ProductService - PurchaseImageUsingCredits - body: ", body);
    return fetch(`${apiURI}/product/image/purchase/credits`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }

  static PurchaseContentPackPP = async (userId, token, productId, packId, price, ppTransactionId) => {
    // console.log("ProductService.PurchaseContentPackPP: User:", userId);
    // console.log("ProductService.PurchaseContentPackPP: Product:", productId);
    // console.log("ProductService.PurchaseContentPackPP: Pack:", packId);

    var body = 
      {
        "transactionId": ppTransactionId,
        "TransactionTimestamp": new Date().toUTCString(),
        "userId": userId,      
        "productType": 1, // content pack type
        "mode": 5, // no purchase / just 'purchase' in sassy
        "productId": `${productId}`,
        "AssetId": `${packId}`,
        "price": price,
        "priceCurrency": "USD"
      };
      
    // console.log("ProductService - PurchaseContentPackPP - body: ", JSON.stringify(body));
    return fetch(`${apiURI}/product/contentpack/purchase/pp`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }

  static PurchaseContentPackCC = async (userId, token, email, productId, packId, price, creditcard) => {
    // console.log("ProductService.PurchaseContentPackCC: User:", userId);
    // console.log("ProductService.PurchaseContentPackCC: Product:", productId);
    // console.log("ProductService.PurchaseContentPackCC: Pack:", packId);
    // console.log("ProductService.PurchaseContentPackCC: Credit:", creditcard);

    var body = 
      {
        "userId": userId,      
        "productType": 1, // content pack type
        "mode": 2, // purchase with $$$
        "productId": `${productId}`,
        "AssetId": `${packId}`,
        "price": price,
        "priceCurrency": "USD",        
        "creditCard": {
            "creditCardNumber": creditcard.creditCardNumber,
            "expirationDateString": creditcard.expirationDateString,
            "cvv": creditcard.cvv,      
            "countryRegion": creditcard.countryRegion,
            "state": creditcard.state,
            "city": creditcard.city,
            "addressLine1": creditcard.addressLine1,
            "addressLine2": creditcard.addressLine2,
            "postalCode": creditcard.postalCode,
            "phoneNumber": creditcard.phoneNumber,
            "email": email,
            "firstName": creditcard.firstName,
            "lastName": creditcard.lastName,
            "paymentProfileId": "",
            "paymentProfileName": ""
          }
      };
      
    // console.log("ProductService - PurchaseContentPackCC - body: ", JSON.stringify(body));
    return fetch(`${apiURI}/product/contentpack/purchase/cc`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }

  static PurchaseCreditPackPP = async (userId, token, creditPackId, price, currency, ppTransactionId) => {
    // console.log("ProductService.PurchaseCreditPackPP: User:", userId);
    // console.log("ProductService.PurchaseCreditPackPP: Pack:", creditPackId);
    // console.log("ProductService.PurchaseCreditPackPP: PP transaction:", ppTransactionId);
    // console.log("ProductService.PurchaseCreditPackPP: PP price:", price);
    // console.log("ProductService.PurchaseCreditPackPP: PP currency:", currency);

    var body = 
      {
        //"transactionId": ppTransactionId,
        "userId": userId,      
        "mode": 3, // no purchase / just 'purchase' in sassy
        "creditPackId": `${creditPackId}`,        
        "price": price,
        "priceCurrency": currency
      };
      
    // console.log("ProductService - PurchaseCreditPackPP - body: ", JSON.stringify(body));
    return fetch(`${apiURI}/credits/pack/add`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }

  static PurchaseCreditPackCC = async (userId, token, email, creditPackId, price, currency, creditcard) => {
    // console.log("ProductService.PurchaseCreditPackCC: User:", userId, " email:", email);
    // console.log("ProductService.PurchaseCreditPackCC: Pack:", creditPackId);
    // console.log("ProductService.PurchaseCreditPackCC: Price:", price);
    // console.log("ProductService.PurchaseCreditPackCC: card:", JSON.stringify(creditcard));

    var body = 
    {
      //"transactionId": ppTransactionId,
      "userId": userId,      
      "mode": 4, // no purchase / just 'purchase' in sassy
      "creditPackId": `${creditPackId}`,                
        "price": price,
        "priceCurrency": currency,        
        "creditCard": {
            "creditCardNumber": creditcard.creditCardNumber,
            "expirationDateString": creditcard.expirationDateString,
            "cvv": creditcard.cvv,      
            "countryRegion": creditcard.countryRegion,
            "state": creditcard.state,
            "city": creditcard.city,
            "addressLine1": creditcard.addressLine1,
            "addressLine2": creditcard.addressLine2,
            "postalCode": creditcard.postalCode,
            "phoneNumber": creditcard.phoneNumber,
            "email": email,
            "firstName": creditcard.firstName,
            "lastName": creditcard.lastName,
            "paymentProfileId": "",
            "paymentProfileName": ""
          }
        };
      
      // console.log("ProductService - PurchaseCreditPackCC - body: ", JSON.stringify(body));
    return fetch(`${apiURI}/credits/pack/purchase/cc`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }
}


export default ProductService;
