import React, { useState, useEffect, useCallback, useRef, memo } from "react";
import MultiDropdown from "../../components/multi-dropdown/MultiDropdown";
import ImageService from "../../services/image.service";
import UserService from "../../services/user.service";
import { useNavigate } from "react-router-dom";
import { useAuth, EnsureToken } from "../../services/auth/use-auth";
import { Image } from "react-bootstrap";

import "./profile.css";
import GalleryTiles from "../../components/galleryTiles/GalleryTiles";
import { SocialIcon } from "react-social-icons";
import {
  Avatar,
  Box,
  Paper,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material";

import ContentPackService from "../../services/contentpack.service";
import PackTiles from "../../components/PackTiles/PackTiles";
import GalleryPackTiles from "../../components/galleryPackTiles/GalleryTiles";
import Tiles from "../../components/Tiles/Tiles";
import TilesView from "../../components/Tiles/TilesView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FiEdit } from "react-icons/fi";
import { SocialShare } from "../../components/social-share/SocialShare";
import { Margin } from "@mui/icons-material";
// Add imports
import { TextField, InputAdornment } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import debounce from "lodash/debounce";

function Profile() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [textGens, setTextGens] = useState([]);
  const [imageGens, setImageGens] = useState([]);
  const [packs, setPacks] = useState([]);

  const [pageImages, setPageImages] = useState(0);
  const [pageFavorites, setPageFavorites] = useState(0);
  const [pageStories, setPageStories] = useState(0);
  const [pageImageGens, setPageImageGens] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const MAX_PAGE_SIZE = 48;

  const [subscription, setSubscription] = useState([]);
  const [credits, setCredits] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const searchInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeSearchTerm, setActiveSearchTerm] = useState("");

  // Memoized handlers at Profile component level
  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
    //e.preventDefault();
  }, []);

  const handleSearchKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        setActiveSearchTerm(searchTerm);
      }
    },
    [searchTerm]
  );

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchTerm]);

  const getGalleryImages = async () => {
    setLoading(true);
    if (currentPage !== 1) {
      setData([]);
    }

    setCurrentPage(1);

    if (auth.user) {
      ImageService.GetUserImages(
        auth.user.uid,
        pageImages,
        MAX_PAGE_SIZE,
        await EnsureToken(auth.user.auth.currentUser)
      )
        .then((res) => {
          // console.log("response", res);

          if (loadingMore) {
            setData((data) => [...data, ...res]);
          } else {
            setData([...res]);
          }

          setLoading(false);
          setShowMore(res.length >= MAX_PAGE_SIZE ? true : false);
        })
        .catch((err) => {
          // console.log("error", err);
          setLoading(false);
        });
    }
  };

  const getGalleryImagesAI = async () => {
    setLoading(true);

    if (currentPage !== 4) {
      setImageGens([]);
    }

    setCurrentPage(4);

    console.log("getGalleryImagesAI page", pageImageGens);
    if (auth.user) {
      ImageService.GetUserImagesAI(
        auth.user.uid,
        pageImageGens,
        MAX_PAGE_SIZE,
        await EnsureToken(auth.user.auth.currentUser)
      )
        .then((res) => {
          // console.log("response", res);
          //setImageGens([...res]);

          setImageGens((imageGens) => [...imageGens, ...res]);
          setLoading(false);
          setShowMore(res.length >= MAX_PAGE_SIZE ? true : false);
        })
        .catch((err) => {
          // console.log("error", err);
          setLoading(false);
        });

      setLoading(false);
      setLoadingMore(false);
    }
  };
  const getGalleryStories = async () => {
    setLoading(true);

    if (currentPage !== 3) {
      setTextGens([]);
    }

    setCurrentPage(3);

    if (auth.user) {
      // ImageService.GetCleanImages(0)
      ImageService.GetUserImagesWithStories(
        auth.user.uid,
        pageStories,
        MAX_PAGE_SIZE,
        await EnsureToken(auth.user.auth.currentUser)
      )
        .then((res) => {
          // console.log("response", res);
          //setTextGens([...res]);
          if (loadingMore) {
            setTextGens((textGens) => [...textGens, ...res]);
          } else {
            setTextGens([...res]);
          }

          setShowMore(res.length >= MAX_PAGE_SIZE ? true : false);
        })
        .catch((err) => {
          // console.log("error", err);
        });
    }

    setLoading(false);
    setLoadingMore(false);
  };

  // Add filter function
  const filterImages = (images) => {
    if (!activeSearchTerm) return images;

    return images.filter(
      (img) =>
        img.title?.toLowerCase().includes(activeSearchTerm.toLowerCase()) ||
        img.description
          ?.toLowerCase()
          .includes(activeSearchTerm.toLowerCase()) ||
        img.generated_by_model
          ?.toLowerCase()
          .includes(activeSearchTerm.toLowerCase())
    );
  };

  const getPackImages = async () => {
    setLoading(true);
    setCurrentPage(2);

    if (auth.user) {
      // console.log(
      //   "fetching gallery images for user: ",
      //   auth.user.uid,
      //   " page: ",
      //   page,
      //   " ..."
      // );

      const response = await ContentPackService.GetContentPacks(0);
      setPacks([...response]);
      // console.log("fetching content packs Data, page: ", 0, "data", response);
      setLoading(false);
    }
  };

  const getFavoriteImages = async () => {
    setLoading(true);

    if (currentPage !== 2) {
      setFavorites([]);
    }

    setCurrentPage(2);

    ImageService.getFavoriteImagesForUser(
      auth.user.uid,
      await EnsureToken(auth.user.auth.currentUser)
    )
      .then((res) => {
        //console.log("response", res);
        if (loadingMore) {
          setFavorites((favorites) => [...favorites, ...res]);
        } else {
          setFavorites([...res]);
        }
        setShowMore(res.length >= MAX_PAGE_SIZE ? true : false);
      })
      .catch((err) => {
        console.log("error", err);
      });

    setLoading(false);
    setLoadingMore(false);
  };

  const loadMoreImagesAI = async () => {
    setLoadingMore(true);
    setPageImageGens(pageImageGens + 1);
    getGalleryImagesAI();
  };
  const loadMoreFavorites = async () => {
    setLoadingMore(true);
    setPageFavorites(pageFavorites + 1);
    getFavoriteImages();
  };

  const loadMoreImages = async () => {
    setLoadingMore(true);
    setPageImages(pageImages + 1);
    getGalleryImages();
  };

  const loadMoreStoriesAI = async () => {
    setLoadingMore(true);
    setPageStories(pageStories + 1);
    getGalleryStories();
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoading(true);
      setCurrentPage(1);

      const fetchData = async () => {
        getGalleryImages();

        UserService.GetUserProfile(
          auth.user.uid,
          await EnsureToken(auth.user.auth.currentUser)
        ).then((res) => {
          setUser(res.data);
        });
        UserService.GetUserSubscription(
          auth.user.uid,
          auth.user.auth.currentUser.accessToken
        )
          .then((response) => {
            // console.log(
            //   "Logged in, credit  info for auth.user.id:",
            //   auth.user.uid,
            //   "\n",
            //   response
            // );
            setCredits(response.subscription.creditProfile);
            setSubscription(response.subscription);
            // // console.log("Subscription: ", JSON.stringify(response.subscription));
          })
          .catch((err) => {
            // console.log("error", err);
          });
      };

      fetchData();
      setLoading(false);
      return () => {
        mounted = false;
      };
    }
  }, [auth.user]);

  const SocialMediaRow = (user) => {
    if (user) {
      return (
        <div className="row mb-2">
          <div className="col d-flex justify-content-center">
            {user && user.facebookUrl ? (
              <SocialIcon
                className="m-2"
                style={{ height: 40, width: 40 }}
                target="_blank"
                url={user.facebookUrl}
              />
            ) : (
              <></>
            )}
            {user && user.instagramUrl ? (
              <SocialIcon
                className="m-2"
                style={{ height: 40, width: 40 }}
                target="_blank"
                url={user.instagramUrl}
              />
            ) : (
              <></>
            )}
            {user && user.twitterUrl ? (
              <SocialIcon
                className="m-2"
                style={{ height: 40, width: 40 }}
                target="_blank"
                url={user.twitterUrl}
              />
            ) : (
              <></>
            )}
            {user && user.youTubeUrl ? (
              <SocialIcon
                className="m-2"
                style={{ height: 40, width: 40 }}
                target="_blank"
                url={user.youTubeUrl}
              />
            ) : (
              <></>
            )}
            {user && user.tikTokUrl ? (
              <SocialIcon
                className="m-2"
                style={{ height: 40, width: 40 }}
                target="_blank"
                url={user.tikTokUrl}
              />
            ) : (
              <></>
            )}
            {user && user.linkedInUrl ? (
              <SocialIcon
                className="m-2"
                style={{ height: 40, width: 40 }}
                target="_blank"
                url={user.linkedInUrl}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const showLegend = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        borderRadius: "10px",
        width: "100%",
        paddingTop: "10px",
        marginBottom: "-10px",
      }}
    >
      <Typography variant="body2" className="col">
        <FontAwesomeIcon size="lg" color="#666" icon={solid("download")} /> -
        download
      </Typography>
      <Typography variant="body2" className="col">
        <FontAwesomeIcon size="lg" color="#666" icon={solid("palette")} /> -
        image studio
      </Typography>
      <Typography variant="body2" className="col">
        <FiEdit
          style={{
            color: "#666",
            width: "24px",
            height: "24px",
          }}
        />
        - story history
      </Typography>
      <Typography variant="body2" className="col">
        <FontAwesomeIcon size="lg" color="#666" icon={solid("share")} /> -
        sharing
      </Typography>
    </div>
  );

  // Add search box component
  const SearchBox = memo(({ value, onChange, onKeyDown }) => (
    <TextField
      inputRef={searchInputRef}
      fullWidth
      variant="outlined"
      placeholder="Search images... (press Enter to search)"
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      autoComplete="off"
      sx={{
        mb: 2,
        "& .MuiOutlinedInput-root": {
          backgroundColor: "white",
          borderRadius: 2,
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  ));

  const ShowMyImageGallery = () => {
    const filteredImages = filterImages(data);
    return (
      <div className="image__gallery">
        <Typography
          variant="h4"
          textAlign="center"
          className="mb-1 mt-5 fw-bold"
        >
          My Image Gallery
        </Typography>
        <SearchBox
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
        />
        <Typography
          sx={{
            margin: 1,
            fontFamily: "Raleway",
            fontSize: 12,
            fontStyle: "italic",
            fontWeight: "400",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          Hover over images to see options (on mobile - tap image).
        </Typography>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "34vh",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Box>
            <GalleryTiles data={filteredImages} type="gallery"></GalleryTiles>
            {showMoreImages(loadMoreImages)}
          </Box>
        )}
      </div>
    );
  };

  const ShowMyStoryGallery = () => {
    return (
      <div className="image__gallery">
        <Typography
          variant="h4"
          textAlign="center"
          className="mb-1 mt-5 fw-bold"
        >
          My Story Gallery
        </Typography>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "34vh",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Box>
            <Typography
              visibility={textGens.length === 0 ? "visible" : "hidden"}
              sx={{
                margin: 1,
                fontFamily: "Raleway",
                fontSize: 24,
                fontStyle: "italic",
                fontWeight: "400",
                marginTop: "40px",
                textAlign: "center",
              }}
            >
              Loading... (or no AI stories found)
            </Typography>
            <GalleryTiles data={textGens} type="story"></GalleryTiles>
            {showMoreImages(loadMoreStoriesAI)}
          </Box>
        )}
      </div>
    );
  };

  const ShowMyFavorites = () => {
    return (
      <div className="image__gallery">
        <Typography
          variant="h4"
          textAlign="center"
          className="mb-1 mt-5 fw-bold"
        >
          My Favorites
        </Typography>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "34vh",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Box>
            <Typography
              // should disappear when data is loaded or after 10 seconds
              visibility={favorites.length === 0 ? "visible" : "hidden"}
              sx={{
                margin: 1,
                fontFamily: "Raleway",
                fontSize: 24,
                fontStyle: "italic",
                fontWeight: "400",
                marginTop: "40px",
                textAlign: "center",
              }}
            >
              Loading... (or no favorites found)
            </Typography>
            <GalleryTiles
              data={favorites}
              loadMore={getFavoriteImages}
            ></GalleryTiles>
            {showMoreImages(loadMoreFavorites)}
          </Box>
        )}

        {/*
          loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "34vh",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Box>
            <Tiles data={favorites} loadMore={getFavoriteImages}></Tiles>
            {showMoreImages(loadMoreFavorites)}
          </Box>


        ) */}
      </div>
    );
  };

  const showMoreImages = (handler) => {
    return showMore && !loading ? (
      <div className="px-4 my-5 text-center">
        <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
          <h5 className="fw-bold color-primary">
            <Button
              className="rounded-pill"
              variant="contained"
              onClick={() => handler()}
            >
              Load More Images
            </Button>
          </h5>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const ShowMyGeneratedImageGallery = () => {
    const filteredImageGens = filterImages(imageGens);
    return (
      <div className="image__gallery">
        <Typography
          variant="h4"
          textAlign="center"
          className="mb-1 mt-5 fw-bold"
        >
          My AI Generated Images
        </Typography>
        <SearchBox
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
        />

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "34vh",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Box>
            <Typography
              visibility={imageGens.length === 0 ? "visible" : "hidden"}
              sx={{
                margin: 1,
                fontFamily: "Raleway",
                fontSize: 24,
                fontStyle: "italic",
                fontWeight: "400",
                marginTop: "40px",
                textAlign: "center",
              }}
            >
              Loading... (or no AI generated images found)
            </Typography>
            <TilesView data={filteredImageGens}></TilesView>
            {showMoreImages(loadMoreImagesAI)}
          </Box>
        )}
      </div>
    );
  };
  const ShowMyContentPackGallery = () => {
    return (
      <div className="pack__gallery">
        <Typography
          variant="h4"
          textAlign="center"
          className="mb-1 mt-5 fw-bold"
        >
          My Content Packs Gallery
        </Typography>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "34vh",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <GalleryPackTiles data={packs}></GalleryPackTiles>
        )}
      </div>
    );
  };

  const ShowNavigationTabs = () => {
    return (
      <div
        className="row my-4"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          backgroundColor: "#f5f5f5",
          borderRadius: "10px",
          marginTop: "20px",
          marginBottom: "20px",
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          border: "1px solid #ddd",
          width: "100%",
          gap: "10px",
          fontSize: "1.0rem",
          fontWeight: "500",
        }}
      >
        <div className="col d-flex justify-content-center">
          <button
            type="button"
            className={`tab-button me-1 ${currentPage === 1 ? "active" : ""}`}
            disabled={currentPage === 1}
            onClick={() => getGalleryImages()}
          >
            My Images
          </button>
          {/*}
          <button
            type="button"
            className={`tab-button me-1 ${currentPage === 2 ? 'active' : ''}`}
            disabled={currentPage === 2}
            onClick={() => getPackImages()}
          >
            My Content Packs
          </button>
          */}
        </div>
        <div className="col d-flex justify-content-center">
          <button
            type="button"
            className={`tab-button me-1 ${currentPage === 2 ? "active" : ""}`}
            disabled={currentPage === 2}
            onClick={() => getFavoriteImages()}
          >
            My Favorites
          </button>
        </div>
        <div className="col d-flex justify-content-center">
          <button
            type="button"
            className={`tab-button me-1 ${currentPage === 3 ? "active" : ""}`}
            disabled={currentPage === 3}
            onClick={() => getGalleryStories()}
          >
            My AI Stories
          </button>
        </div>
        <div className="col d-flex justify-content-center">
          <button
            type="button"
            className={`tab-button me-1 ${currentPage === 4 ? "active" : ""}`}
            disabled={currentPage === 4}
            onClick={() => getGalleryImagesAI()}
          >
            My AI Images
          </button>
        </div>
        {showLegend}
      </div>
    );
  };

  const ShowCurrentUserProfile = (user) => {
    if (user) {
      return (
        <Box>
          <div className="row mb-2">
            <div className="col d-flex justify-content-center">
              <Avatar
                sx={{
                  width: 140,
                  height: 140,
                  margin: "auto",
                }}
                src={auth.user.photoURL}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex justify-content-center">
              <div className="display-5 fw-bold">{auth.user.displayName}</div>
            </div>
          </div>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const renderSubscriptionInfo = () => {
    return (
      subscription &&
      credits && (
        <Paper
          sx={{
            padding: "10px",
            backgroundColor: "#f2f2f2",
            border: "1px solid #e0e0e0",
            borderRadius: "10px",
            marginBottom: "20px",
          }}
        >
          <div className="row">
            <div className="col d-flex justify-content-left">
              <div className="fw-bold">
                Subscription Plan:{" "}
                <span style={{ color: "#183e4b" }}>
                  {subscription.subscriptionName}
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col d-flex justify-content-left">
              <div className="fw-bold">
                Current Monthly Credits:{" "}
                <span style={{ color: "#183e4b" }}>
                  {credits.numSubscriptionCreditsRemaining}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-left">
              <div className="fw-bold">
                Current Wallet Credits:{" "}
                <span style={{ color: "#183e4b" }}>
                  {credits.numWalletCreditsRemaining}
                </span>
              </div>
            </div>
          </div>
        </Paper>
      )
    );
  };

  const ShowManageProfileButtons = (user) => {
    if (user) {
      return (
        <>
          <div className="col d-flex justify-content-center">
            <button
              type="button"
              className="button"
              onClick={() =>
                navigate({
                  pathname: "/profile/edit",
                  search: "?tab=settings",
                })
              }
            >
              Edit Profile
            </button>

            <button
              type="button"
              className="button"
              onClick={() =>
                navigate({
                  pathname: "/profile/edit",
                  search: "?tab=membership",
                })
              }
            >
              Manage Membership
            </button>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const dropdownFilters = (
    <div className="d-flex flex-wrap align-content-start mb-3">
      <MultiDropdown
        className="mt-3 me-3"
        list={["Category 1", "Category 2", "Category 3"]}
      >
        Category
      </MultiDropdown>
      <MultiDropdown
        className="mt-3 me-3"
        list={["Topic 1", "Topic 2", "Topic 3"]}
      >
        Topic
      </MultiDropdown>
      <MultiDropdown className="mt-3 me-3" list={["Tag 1", "Tag 2", "Tag 3"]}>
        Tag
      </MultiDropdown>
      <MultiDropdown
        className="mt-3 me-3"
        list={["Color 1", "Color 2", "Color 3"]}
      >
        Color
      </MultiDropdown>
    </div>
  );

  return (
    <div
      className="container my-3"
      style={{
        display: "flex",
        minWidth: "400px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        backgroundColor: "#f5f5f5",
        borderRadius: "10px",
        marginTop: "20px",
        marginBottom: "20px",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        border: "1px solid #ddd",
        width: "100%",
      }}
    >
      {ShowCurrentUserProfile(user)}

      {SocialMediaRow(user)}

      {renderSubscriptionInfo(user)}

      {
        //ShowManageProfileButtons(user)
      }
      {ShowNavigationTabs()}

      {currentPage === 1 ? ShowMyImageGallery() : <></>}
      {
        //currentPage === 2 ? ShowMyContentPackGallery() : <></>
      }
      {currentPage === 2 ? ShowMyFavorites() : <></>}
      {currentPage === 3 ? ShowMyStoryGallery() : <></>}
      {currentPage === 4 ? ShowMyGeneratedImageGallery() : <></>}
    </div>
  );
}

export default Profile;
