/* eslint-disable react/function-component-definition */
import * as React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../services/auth/use-auth";
import useGoogleOneTap from "../../hooks/useGoogleOneTap";
import {GoogleAuthProvider} from "firebase/auth";
import UserService from "../../services/user.service";
import {Backdrop, Box, CircularProgress, Divider, Grid} from "@mui/material";

import "./FreeSignUp.scss";
import {useState} from "react";

const FreeSignUpPage = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [displayName, setDisplayName] = React.useState("");
    const [showEmailPassword, setShowEmailPassword] = React.useState(false);

    const [email, setEmail] = React.useState("");
    const [emailValid, setEmailValid] = React.useState(false);
    const [passwordValid, setPasswordValid] = React.useState(false);
    const [displayNameValid, setDisplayNameValid] = React.useState(false);
    const [formValid, setFormValid] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);

    const onSetDisplayName = (displayName) => {
        setDisplayName(displayName);
        setDisplayNameValid(displayName.length > 0);
        setFormValid(displayNameValid && emailValid && passwordValid);
    };
    const onSetEmail = (email) => {
        setEmail(email);
        setEmailValid(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/.test(email));
        setFormValid(displayNameValid && emailValid && passwordValid);
    };
    const onSetPassword = (password) => {
        setPassword(password);
        setPasswordValid(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm.test(password)
        );
        setFormValid(displayNameValid && emailValid && passwordValid);
    };
    const handleSignUp = async () => {
        setLoading(true);
        // console.log("loading...");
        try {
            const signUpRes = await auth.signup(email, password, displayName);
            if (signUpRes?.userId) {
                var currentUser = await auth.signin(email, password);
                // console.log("Successfully created account using email and password", currentUser);
                // console.log("loading complete...");
                // navigate("/pricing", { state: { email, password, displayName } }); // BETA+
                navigate("/");
            }
        } catch (error) {           
            setError(true);
            setErrMsg("Failed to create new user - please contact support team or try another method.");
        }
        setLoading(false);
    };
    const handleGoogleOneTapResponse = async (response) => {
        setLoading(true);
        try {
            // console.log(
            //   "SUCCESS: Signin with Google One Tap Success: ",
            //   response.credential
            // );
            const credential = response.credential;
            const googleAuthCredential = GoogleAuthProvider.credential(credential);
            // console.log("googleAuthCredential: ", googleAuthCredential);
            var result = await auth.signInWithCredentialFlow(googleAuthCredential);
            // console.log("result: ", result);
            var userDetails = auth.getAdditionalUserInfoFlow(result);
            // console.log("userDetails: ", userDetails);
            var currentUser = result.user;
            // console.log("Successfully created account using Google");
            const isNewUser = userDetails.isNewUser;
            if (isNewUser) {
                // console.log("Creating New user");
                const newUser = {
                    userId: currentUser.uid,
                    email: currentUser.email,
                    password: null,
                    displayName: currentUser.displayName,
                    photoURL: currentUser.photoURL,
                };
                var createNewUserRes = await UserService.CreateNewUserAccount(newUser);
                // console.log(createNewUserRes);
                if (createNewUserRes?.userId) {
                    navigate("/");
                } else {
                    alert("Failed to create new user - please contact support team or try another method.");
                }
            } else {
                navigate("/");
            }
        } catch (error) {
            setError(true);
            setErrMsg("Failed to create new user - please contact support team or try another method.");
            
            // console.log("ERROR: Signin with Google One Tap Error: ", error);

        }
        setLoading(false);
    };

    useGoogleOneTap({
        // onError: (error) => // console.log("Google OneTap Error: ", error),
        // onComplete: (response) => // console.log("Google OneTap Success: ", response),
        disabled: auth.user ? true : false,
        showPrompt: false,
        disableCancelOnUnmount: false,
        googleConfig: {
            auto_select: true,
            cancel_on_tap_outside: false,
            callback: handleGoogleOneTapResponse,
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        },
    });

    const inputTermsPrivacy = (
        <div className="text-center">
            <input
                type="checkbox"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(!acceptTerms)}
                id="terms"
            />
            <label htmlFor="terms" className="terms__label">
                { // open in new page
            }
                I agree to <a target="_blank"  href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce">terms</a> and <a target="_blank" href="https://app.termly.io/document/privacy-policy/d7cbc9ff-588c-4a7d-962f-f8c5e4913d5e">privacy policy</a>
            </label>
        </div>
    );

    return (
        <main className="">
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Paper
                sx={{
                    width: "100%",
                    minWidth: "400px",
                    maxWidth: "600px",
                    mx: "auto", // margin left & right
                    my: 4, // margin top & botom
                    py: 3, // padding top & bottom
                    px: 2, // padding left & right
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    borderRadius: "sm",
                    boxShadow: "md",
                }}
            >
                <div>
                    <h3 style={{textAlign: "center", marginBottom: "20px"}}>Join</h3>
                    {inputTermsPrivacy}
                    <ul>
                        <li>
                            <h6 
                              style={{
                                marginBottom: "15px",
                                marginTop: "20px"
                              }}
                            >
                                { // refresh page if Google not visible 
                                }
                                Sign up with Google (<Link to="/signup">refresh</Link> page if Google not visible))
                            </h6>
                            {acceptTerms}
                            <div
                                id="google-signin-button"
                                className={`g_id_signin ${!acceptTerms ? 'disabled' : ''}`}
                                data-type="standard"
                                data-context="signup"
                                data-size="large"
                                data-theme="outline"
                                data-text="sign_in_with"
                                data-shape="rectangular"
                                data-logo_alignment="left"
                            ></div>                            
                        </li>

                        <li style={{marginTop: "40px"}}>
                            <h6 style={{marginBottom: "15px"}}>Sign up with Email and Password</h6>
                            <div className={`free__signup__form ${!acceptTerms ? 'disabled' : ''}`}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField
                                            style={{marginTop: "10px"}}
                                            fullWidth
                                            required
                                            size="small"
                                            type="text"
                                            placeholder="First Name"
                                            onChange={(e) => onSetDisplayName(e.target.value)}
                                            label="First Name"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            style={{marginTop: "10px"}}
                                            fullWidth
                                            required
                                            size="small"
                                            type="text"
                                            placeholder="Last Name"
                                            onChange={(e) => onSetDisplayName(e.target.value)}
                                            label="Last Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            style={{marginTop: "20px"}}
                                            required
                                            size="small"
                                            type="email"
                                            error={emailValid || email.length < 4 ? false : true}
                                            placeholder="Email"
                                            onChange={(e) => onSetEmail(e.target.value)}
                                            // pass down to FormLabel as children
                                            label="Email"
                                        />
                                        {!emailValid && email.length > 4 ? (
                                            <FormHelperText
                                                sx={(theme) => ({
                                                    color: "red",
                                                    mx: "auto",
                                                })}
                                            >
                                                Please enter a valid email address
                                            </FormHelperText>
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            style={{marginTop: "20px"}}
                                            fullWidth
                                            type="password"
                                            size="small"
                                            required
                                            error={!passwordValid && password.length < 4 ? true : false}
                                            placeholder="Password"
                                            onChange={(e) => onSetPassword(e.target.value)}
                                            label="Choose a password"
                                        />
                                        {!passwordValid && password.length > 0 ? (
                                            <FormHelperText
                                                sx={(theme) => ({
                                                    color: "red",
                                                    mx: "auto",
                                                })}
                                            >
                                                <ul>
                                                    <li>at least 8 characters</li>
                                                    <li>
                                                        must contain at least 1 uppercase letter, 1 lowercase
                                                        letter, and 1 number
                                                    </li>
                                                </ul>
                                            </FormHelperText>
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                </Grid>

                                <Divider sx={{my: 2}}/>
                                {error ? (
                                    <Typography
                                        sx={(theme) => ({
                                            color: "red",
                                            mx: "auto",
                                        })}
                                    >
                                        {errMsg}
                                    </Typography>
                                ) : (
                                    <></>
                                )}

                                <div style={{textAlign: "center"}}>
                                    <Button
                                        variant="contained"
                                        style={{borderRadius: "50px"}}
                                        sx={{mt: 5}}
                                        onClick={handleSignUp}
                                        disabled={!emailValid || !passwordValid || !displayNameValid}
                                    >
                                        Get Started
                                    </Button>
                                    <Link to="/profile/edit?tab=settings" style={{color: "#000000", fontSize: "13px", fontWeight: "600"}}>
                                        <Box sx={{mt: 1, display: 'flex', flexDirection: 'column', textAlign: "center"}}>
                                            Already have an account?
                                        </Box>
                                    </Link>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </Paper>
        </main>
    );
};

export default FreeSignUpPage;
