

export default function GetFriendlyUsername(name) {
    //console.log("GetFriendlyUsername: " + name);
    if (name === null) return "";
    if (name.length === 0) return "";
    if (name.length === 1) return name;
    let buffer = name.replaceAll("_", " ");
    let buffer2 = buffer.replaceAll("_", " ");

    // create array of tokens from buffer
    let tokens = buffer2.split(" ");

    // remove any empty tokens
    
    tokens = tokens.filter((token) => {
      return token.length > 0;
    });

    // capitalize first letter of each token
    /*
    tokens = tokens.map((token) => {
      return token.charAt(0).toUpperCase() + token.slice(1);
    });
    */
    // return joined tokens
    let result = tokens.join(" ");
    //console.log("GetFriendlyUsername: " + result);
    return result;
  }

export function LogSomething(something) {
  if (process.env.NODE_ENV === "development" || process.env.WEB_DEBUGFLAG === "true") {
    console.log(something);
  }
}
export function TraceSomething(something) {
  if (process.env.NODE_ENV === "development" && process.env.WEB_TRACEFLAG === "true") {
    console.log(something);
  }
}
export function ReplaceEncodedCharactersReverse(buffer) {
  let result = buffer.replaceAll("%20", " ");
  result = result.replaceAll("%22", "\"");
  result = result.replaceAll("%23", "#");
  result = result.replaceAll("%24", "$");
  result = result.replaceAll("%25", "%");
  result = result.replaceAll("%26", "&");
  result = result.replaceAll("%27", "'");
  result = result.replaceAll("%28", "(");
  result = result.replaceAll("%29", ")");
  result = result.replaceAll("%2A", "*");
  result = result.replaceAll("%2B", "+");
  result = result.replaceAll("%2C", ",");
  result = result.replaceAll("%2D", "-");
  result = result.replaceAll("%2E", ".");
  result = result.replaceAll("%2F", "/");
  result = result.replaceAll("%3A", ":");
  result = result.replaceAll("%3B", ";");
  result = result.replaceAll("%3C", "<");
  result = result.replaceAll("%3D", "=");
  result = result.replaceAll("%3E", ">");
  result = result.replaceAll("%3F", "?");
  result = result.replaceAll("%40", "@");
  result = result.replaceAll("%5B", "[");
  result = result.replaceAll("%5C", "\\");
  result = result.replaceAll("%5D", "]");
  result = result.replaceAll("%5E", "^");
  result = result.replaceAll("%5F", "_");
  result = result.replaceAll("%60", "`");
  result = result.replaceAll("%7B", "{");
  result = result.replaceAll("%7C", "|");
  result = result.replaceAll("%7D", "}");
  result = result.replaceAll("%7E", "~");

  return result;
}

export function ReplaceEncodedCharacters(buffer) {
  let result = buffer.replaceAll(" ", "%20");
  result = result.replaceAll("\"", "%22");
  result = result.replaceAll("#", "%23");
  result = result.replaceAll("$", "%24");
  result = result.replaceAll("%", "%25");
  result = result.replaceAll("&", "%26");
  result = result.replaceAll("'", "%27");
  result = result.replaceAll("(", "%28");
  result = result.replaceAll(")", "%29");
  result = result.replaceAll("*", "%2A");
  result = result.replaceAll("+", "%2B");
  result = result.replaceAll(",", "%2C");
  result = result.replaceAll("-", "%2D");
  result = result.replaceAll(".", "%2E");
  result = result.replaceAll("/", "%2F");
  result = result.replaceAll(":", "%3A");
  result = result.replaceAll(";", "%3B");
  result = result.replaceAll("<", "%3C");
  result = result.replaceAll("=", "%3D");
  result = result.replaceAll(">", "%3E");
  result = result.replaceAll("?", "%3F");
  result = result.replaceAll("@", "%40");
  result = result.replaceAll("[", "%5B");
  result = result.replaceAll("\\", "%5C");
  result = result.replaceAll("]", "%5D");
  result = result.replaceAll("^", "%5E");
  result = result.replaceAll("_", "%5F");
  result = result.replaceAll("`", "%60");
  result = result.replaceAll("{", "%7B");
  result = result.replaceAll("|", "%7C");
  result = result.replaceAll("}", "%7D");
  result = result.replaceAll("~", "%7E");
  return result;
}

export function GetRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function GetRandomIntMinMax(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function GetRandomFloat(min, max) {
  return Math.random() * (max - min) + min;
}

export function PrepareDisplayString(buffer) {
  let clean = ReplaceEncodedCharactersReverse(buffer);
  let result = ReplaceSpecialCharactersReverse(clean);
  return result;
}
export function SafelyEncodeDisplayString(buffer) {
  let clean = ReplaceEncodedCharacters(buffer);
  let result = ReplaceSpecialCharacters(clean);
  return result;
}

export function ReplaceSpecialCharacters(buffer) {
  let result = buffer.replaceAll("&", "&amp;");
  result = result.replaceAll("<", "&lt;");
  result = result.replaceAll(">", "&gt;");
  result = result.replaceAll("\"", "&quot;");
  result = result.replaceAll("'", "&apos;");
  return result;
}

export function ReplaceSpecialCharactersReverse(buffer) {
  let result = buffer.replaceAll("&amp;", "&");
  result = result.replaceAll("&lt;", "<");
  result = result.replaceAll("&gt;", ">");
  result = result.replaceAll("&quot;", "\"");
  result = result.replaceAll("&apos;", "'");
  result = result.replaceAll("#39;", "'");

  return result;
}

