import { apiURI } from "../shared/api.settings";
import axios from 'axios';
import { LogSomething, TraceSomething } from "../utils/utilsStringFuncs";

class ImageService {
  
  static GetImageByIdentifier = async (userId, identifier, token) => {
    return fetch(`${apiURI}/image/${identifier}?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());
  };

  static GetRelatedImages = async (userId, identifier, maxResults, token) => {
    return fetch(
      `${apiURI}/image/${identifier}/related?max=${maxResults}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetFeaturedImages = async (userId, page = 0, pageSize = 24, token) => {
    return fetch(
      `${apiURI}/image/featured?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetCleanImages = async (userId, page = 0, pageSize = 24, token) => {
    var images = this.GetImages(userId, page, pageSize, token);
    // loop through images and replace space in url with %20
    for (var i = 0; i < images.length; i++) {
      images[i].public_url_thumbnail = images[i].public_url_thumbnail.replace(
        / /g,
        "%20"
      );
      images[i].public_url_protected = images[i].public_url_protected.replace(
        / /g,
        "%20"
      );
      images[i].public_url_reduced = images[i].public_url_reduced.replace(
        / /g,
        "%20"
      );
    }
    return images;
  };

  static GetImages = async (userId, page = 0, pageSize = 24, token) => {
    return fetch(
      `${apiURI}/image/?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetUserImages = async (userId, page = 0, pageSize = 24, token) => {
    return fetch(
      `${apiURI}/image/user?userId=${userId}&page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetUserImagesAI = async (userId, page = 0, pageSize = 24, token) => {
    return fetch(
      `${apiURI}/image/generated/user?userId=${userId}&page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetUserImagesWithStories = async (
    userId,
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/user/textgen?userId=${userId}&page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetUserImage = async (userId, imageId, token) => {
    return fetch(`${apiURI}/image/${imageId}/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());
  };

  static GetImagesBySearchTerm = async (
    userId,
    searchTerm = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/search/${searchTerm}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetImagesByPhraseSearchTerm = async (
    userId,
    searchTerm = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/search/phrase/${searchTerm}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetImagesByTag = async (
    userId,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/tag/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByCategoryAndTag = async (
    userId,
    category,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/category/${category}/tag/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByCategoryAndHeight = async (
    userId,
    category,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/category/${category}/height/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByCategoryAndTopic = async (
    userId,
    category,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/category/${category}/topic/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByCategoryAndWidth = async (
    userId,
    category,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/category/${category}/width/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByCategoryAndColor = async (
    userId,
    category,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/category/${category}/color/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByTopicAndTag = async (
    userId,
    topic,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/topic/${topic}/tag/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByTopic = async (
    userId,
    topic = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/topic/${topic}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetImagesByMaterial = async (
    userId,
    material = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/materials/${material}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetImagesByCategory = async (
    userId,
    category = "all",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/category/${category}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImageUploadsByUser = async (
    userId,
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/${userId}/uploads/?page=${page}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };


  static GetRandomImagesByCategory = async (
    userId,
    category = "all",
    page = 0,
    pageSize = 24,
    maxImages = 20,
    token
  ) => {
    TraceSomething("GetRandomImagesByCategory: " +  userId + " - " + token);
    return fetch(
      `${apiURI}/image/random/category/${category}?page=${page}&pageSize=${pageSize}&maxImages=${maxImages}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
// image.service.js


/*
static GetRandomImagesByCategory = async ( userId,
  category = "all",
  page = 0,
  pageSize = 24,
  maxImages = 20,
  token) => {
  const response = await axios.get(`${apiURI}/image/random/category/${category}?page=${page}&pageSize=${pageSize}&maxImages=${maxImages}&userId=${userId}`);
  return response.data;
};
*/

  static GetImagesByColor = async (
    userId,
    color = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/tag/color/${color}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByHeight = async (
    userId,
    tagValue = "1024",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/tag/height/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByWidth = async (
    userId,
    tagValue = "1024",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/tag/width/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  /*
    static getFavoriteForUser = async (userId, token) => {
        // console.log("getFavoriteForUser: ", userId);
        return fetch(`${apiURI}/image/user/${userId}/favorites`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        .then((response) => response.json());
    }
    */

  static getFavoriteImagesForUser = async (userId, token) => {
    //console.log("getFavoriteImagesForUser: ", userId);

    return fetch(`${apiURI}/image/user/${userId}/favorites?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());
  };
  static GetFavoriteStatus = async (userId, imageId, token) => {
    //console.log("getFavoriteImagesForUser: ", userId);

    return fetch(`${apiURI}/image/user/${userId}/favorites/${imageId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());
  };
  static favoriteImage = async (userId, imageId, mode, token) => {
    //console.log("favoriteImage:", userId, imageId, mode);
    // fire and forget
    // get method
    return fetch(
      `${apiURI}/collections/favorites/${userId}/${imageId}?mode=${mode}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  /*const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      ImageService.UploadImage(formData).then((response) => {
        navigate(`/image/${response.id}`);
      }
      );
    }
  };
  */

  static UploadImage = async (userId, bytes, thumbnail, meta, token) => {
    var body = {
      userId: userId,
      productType: 20, //image upload
      mode: 1, // "pay with credits"
      imageMetadata: meta,
      imageBytes: bytes,
      imageBytesThumbnail: bytes,
      productId: "68",
      imageUrl: "",
      assetId: "",
    };

    console.log("ProductService - UploadImage - body: ", body);
    return fetch(`${apiURI}/product/image/upload`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  };

  static DescribeImage = async (userId, formData, thumbnail, token) => {
    var body = {
      userId: userId,
      productType: 25, 
      mode: 1,
      imageBytes: formData,
      imageBytesThumbnail: null,
      productId: "67",
      imageUrl: "",
      assetId: "",
    };
/*
"productId": 67,
  "name": "image-describe-low",
  "description1": "smartai.studio - describe image - 256tokens - low tier",
  */
    console.log("ProductService - DescribeImage - body: ", body);
    return fetch(`${apiURI}/product/image/describe`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  };

  static GenerateTextFromImageContext = async (context, imageData, themes) => {
    //console.log("GenerateTextFromImageContext:", context, imageData, themes);

    // sample payload
    /*
            {
                "prompt":"Doug the barbarian was a well loved member of the community, well respected for his work ethic and his love for animals, but harbored a horrible secret.",
                "max_new_tokens":1024,
                "temperature":0.3,
                "top_k":0,
                "top_p":0.9,
                "do_sample":true,
                "repetition_penalty":1.0
            }
        */

    // sample url
    // http://smartmonster:8081/capability/text/generation/mistral/7b/chat
    /*
        let colorTags = imageData.tags.filter((tag) => tag.startsWith("color:"));        
        let materialsTags = imageData.tags.filter((tag) => tag.startsWith("materials:"));
        let topicTags = imageData.tags.filter((tag) => tag.startsWith("topic:"));
        //let categoryTags = imageData.tags.filter((tag) => tag.startsWith("category:"));
        //let heightTags = imageData.tags.filter((tag) => tag.startsWith("height:"));
        //let widthTags = imageData.tags.filter((tag) => tag.startsWith("width:"));
        let tags = imageData.tags.filter((tag) => tag.startsWith("tag:"));
        let locations = imageData.tags.filter((tag) => tag.startsWith("location:"));
        
        // TODO - Add tagsExtra to the prompt
        let promptPrefix = "Generate a " + imageData.category + " " + context +  " establishing context with the following ";
        
        // create delimited string of color tags after joining them, remove the 'color:' prefix
        let colorTagsString = colorTags.join(", ").r
        // create delimited string of materials tags after joining them, remove the 'materials:' prefix
        let materialsTagsString = materialsTags.join(", ").replace("materials:","");
        // create delimited string of topic tags after joining them, remove the 'topic:' prefix
        let topicTagsString = topicTags.join(", ").replace("topic:","");
        // create delimited string of tags after joining them, remove the 'tag:' prefix
        let tagsString = tags.join(", ").replace("tag:","");
        // create delimited string of locations after joining them, remove the 'location:' prefix
        let locationsString = locations.join(", ").replace("location:","");
            
        console.log("GenerateTextFromImageContext colorTagsString:", colorTagsString);
        console.log("GenerateTextFromImageContext materialsTagsString:", materialsTagsString);
        console.log("GenerateTextFromImageContext topicTagsString:", topicTagsString);
        console.log("GenerateTextFromImageContext tagsString:", tagsString);
        console.log("GenerateTextFromImageContext locationsString:", locationsString);

          let promptComplete = "[INST] " + promptPrefix + 
            (colorTags.length > 0) ? " colors:" + colorTags.join(", ").replace("color:","") : "" + 
            (materialsTags.length > 0) ? " and materials: " + materialsTags.join(", ") : "" + 
            (topicTags.length > 0) ? " and topics: " + topicTags.join(", ") : "" +       
            (tags.length > 0) ? " and tags: " + tags.join(", ") : "" + 
            (themes.length > 0) ? " and incorporate the following thematic elements: " + themes.split(", ") : "" +
            (locations.length > 0) ? " and locations: " + locations.join(", ") : "" +
            + " [/INST]";
        
*/
    // TODO - Add tagsExtra to the prompt
    let promptPrefix = "Generate a " + imageData.category + " " + context;

    let promptComplete = "[INST] " + promptPrefix + " [/INST]";

    // console.log("GenerateTextFromImageContext promptComplete:", promptComplete);

    let payload = {
      prompt: promptComplete,
      max_new_tokens: 1024,
      temperature: 0.3,
      top_k: 0,
      top_p: 0.9,
      do_sample: true,
      repetition_penalty: 1.0,
    };

    // console.log("GenerateTextFromImageContext payload:", payload);

    /*
        return fetch(`${apiURI}/capability/text/generation/mistral/7b/chat`, {
            method: 'POST',
            mode: "no-cors", // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload),
        }).then((response) => response.json());
*/

    /*
 



      
            // console.log("GenerateTextFromImageContext promptComplete:", promptComplete);
        // POST request using fetch with set headers        
        const requestOptions = {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            "Content-Type": "application/json",
          },
            body: JSON.stringify({ 
                prompt: promptComplete,
                max_new_tokens: 512,
                temperature: 0.3,
                top_k: 0,
                top_p: 0.9,
                do_sample: true,
                repetition_penalty: 1.0
            })
        };
        fetch(`${apiGenerateURI}/capability/text/generation/mistral/7b/chat`, requestOptions)
        .then(response => response.json());

        // console.log("GenerateTextFromImageContext requestOptions:", requestOptions);
*/
  };

  /*
    static GetImagePrice = async (width, height) => {
      return fetch(`${apiURI}/price?width=${width}&height=${height}`)
        .then((response) => response.json());
    }
  
    static GetImagePricesheet = async (color = '',page = 0, pageSize = 24) => {
      return fetch(`${apiURI}/prices`)
        .then((response) => response.json());
    }
    */
}

export default ImageService;
